import React from 'react';
import { Form } from 'react-bootstrap';

const CardComponentForm = ({ formValues, setFormValues }) => (
    <>
        <Form.Group controlId="formCardTitle">
            <Form.Label>Card Title</Form.Label>
            <Form.Control
                type="text"
                name="title"
                value={formValues.title || ''}
                onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
            />
        </Form.Group>
        <Form.Group controlId="formCardImageUrl">
            <Form.Label>Image URL</Form.Label>
            <Form.Control
                type="text"
                name="imageUrl"
                value={formValues.imageUrl || ''}
                onChange={(e) => setFormValues({ ...formValues, imageUrl: e.target.value })}
            />
        </Form.Group>
        <Form.Group controlId="formCardBodyText">
            <Form.Label>Body Text</Form.Label>
            <Form.Control
                type="text"
                name="bodyText"
                value={formValues.bodyText || ''}
                onChange={(e) => setFormValues({ ...formValues, bodyText: e.target.value })}
            />
        </Form.Group>
    </>
);

export default CardComponentForm;
