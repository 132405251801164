import React, { useState, useRef } from 'react';
import { useDrop } from 'react-dnd';

const DroppableColumn = ({ id, className, onDropComponent, children, resetDrop, rowId }) => {
    const ref = useRef(null);
    const [hasDropped, setHasDropped] = useState(false); // Track drop state

    const [{ isOver }, drop] = useDrop({
        accept: "component",
        drop: (item, monitor) => {
            const isOverChild = monitor.isOver({ shallow: true }); // Only trigger on direct parent, not children
            if (!isOverChild || hasDropped) {
                return; // Prevent drop if it's over a child or already dropped
            }

            // Ensure the `onDropComponent` function receives the correct parameters
            onDropComponent(id, item); // Pass the dropped item

            setHasDropped(true); // Prevent further drops
            setTimeout(() => {
                setHasDropped(false); // Reset after delay to allow future drops
            }, 1000);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }), // Check if direct parent is hovered
        }),
    });

    return (
        <div ref={drop} className={`${className} ${isOver ? 'highlight' : ''}`}>
            {children}
        </div>
    );
};

export default DroppableColumn;
