import React from 'react';
import { Card } from 'react-bootstrap';

const FeatureCardsComponent = ({ features, styles = {} }) => (
    <div className="product-inner-wrap">
        <div className="g-4 row">
            {features.map((feature, idx) => (
                <div className="col-md-4" key={idx}>
                    <Card className="h-100">
                        <div className="image-wrap">
                            <Card.Img variant="top" src={feature.image} alt={feature.title} />
                        </div>
                        <Card.Body>
                            <Card.Title style={styles.title || {}}>
                                {feature.title}
                            </Card.Title>
                            <Card.Text style={styles.description || {}} className="product-description">
                                {feature.description}
                            </Card.Text>
                            {/* If there’s a button section in styles, we can add a button and apply styles */}
                            {styles.button && (
                                <div className="text-center mt-3">
                                    <button
                                        style={{
                                            ...styles.button,
                                            padding: '10px 20px',
                                            border: 'none',
                                            borderRadius: styles.button.borderRadius || '5px'
                                        }}
                                    >
                                        Learn More
                                    </button>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            ))}
        </div>
    </div>
);

export default FeatureCardsComponent;
