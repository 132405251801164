import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './pages/component/AuthContext';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import ProductPage from './pages/ProductPage';
import ProductDetailPage from './pages/ProductDetailPage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CartProvider } from './pages/component/CartContext';
import Header from './pages/component/Header';
import CartPage from './pages/CartPage';
import BottomNav from './pages/component/BottomNav';
import Footer from './pages/component/Footer';
import SplashScreen from './pages/component/SplashScreen';
import { AppDataProvider, useAppData } from './pages/component/AppDataContext';
import PageBuilder from './pages/PageBuilder';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CustomPages from './pages/CustomPages';

const PublicRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated && element.type.name !== 'LoginAsCustomer' ? <Navigate to="/account" replace /> : element;
};

const AppContent = () => {
  const { isDataLoading } = useAppData(); // Assuming isDataLoading is part of AppDataContext
  const [showBackdrop, setShowBackdrop] = useState(false);
  const location = useLocation();

  // Function to toggle the backdrop visibility
  const toggleBackdrop = (isVisible) => {
    setShowBackdrop(isVisible);
  };

  if (isDataLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      <div>
        <div className="app-container">
          {!location.pathname.includes('/page-builder') && (
            <div className="header">
              <Header toggleBackdrop={toggleBackdrop} />
            </div>
          )}
          <div className="content">
            <div className="app">
              <Routes>
                <Route path="/" element={<PublicRoute element={<Home />} />} />
                <Route path="/products" element={<PublicRoute element={<ProductPage />} />} />
                <Route path="/products/:productId" element={<PublicRoute element={<ProductDetailPage />} />} />
                <Route path="/cart" element={<PublicRoute element={<CartPage />} />} />
                <Route path="/page-builder/:pageId" element={<PublicRoute element={<PageBuilder />} />} />
                <Route path="/pages/:pageId" element={<PublicRoute element={<CustomPages />} />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
          <div className="footer">
            <div className={`bghide ${showBackdrop ? 'bgtransparent' : ''}`}></div>
            <BottomNav />
          </div>
        </div>
         {location.pathname !== '/page-builder' && (
            <Footer />
          )}
       
      </div>      
    </>
  );
};

const App = () => {
  return (
    <Router>
      <DndProvider backend={HTML5Backend}>
        <AppDataProvider>
          <CartProvider>
            <AuthProvider>
              <AppContent />
            </AuthProvider>
          </CartProvider>
        </AppDataProvider>
      </DndProvider>
    </Router>
  );
};

export default App;
