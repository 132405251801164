// components/droppable/DroppableRow.js
import React, { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';

const DroppableRow = ({ children, index, moveRow, onRemoveRow, onCloneRow }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'row',
        hover(item) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    });

    const [, drag] = useDrag({
        type: 'row',
        item: { type: 'row', index },
    });

    drag(drop(ref));

    return (
        <div ref={ref} className="droppable-row">
            {children}
        </div>
    );
};

export default DroppableRow;
