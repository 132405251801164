import React from 'react';
import { Form } from 'react-bootstrap';

const DividerForm = ({ formValues, setFormValues }) => (
    <Form.Group controlId="formDivider">
        <Form.Label>Divider</Form.Label>
        <Form.Control
            type="text"
            name="divider"
            value={formValues.divider || ''}
            onChange={(e) => setFormValues({ ...formValues, divider: e.target.value })}
        />
    </Form.Group>
);

export default DividerForm;
