import React from 'react';

function ImageComponent({ imgSrc, styles = {} }) {
    const imageStyles = styles?.imageStyles || {};

    // Separate out styling properties and link
    const {
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        width,
        height,
        borderColor,
        borderWidth,
        borderRadius,
        boxShadow,
        textAlign,
        link // link property to wrap the image if needed
    } = imageStyles;

    // Applied styles for the image element
    const appliedStyles = {
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        width,
        height,
        borderColor,
        borderWidth,
        borderRadius,
        boxShadow,
        textAlign,
        display: 'block', // Ensure the image is block-level for margin to work as expected
        borderStyle: borderColor || borderWidth ? 'solid' : undefined, // Apply border style only if color/width is defined
    };

    // Wrap image in a link if the `link` property is present
    const imageElement = (
        <img 
            src={imgSrc} 
            alt="Dynamic Content" 
            style={appliedStyles} 
        />
    );

    return (
        <div style={{ textAlign: textAlign || 'center' }}> {/* Center alignment if no textAlign provided */}
            {link ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {imageElement}
                </a>
            ) : (
                imageElement
            )}
        </div>
    );
}

export default ImageComponent;
