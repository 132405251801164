import React, { useState } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';

const ImageOverlayCardForm = ({ formValues, setFormValues }) => {
    const [showLibrary, setShowLibrary] = useState(false);

    const handleImageSelect = (url) => {
        setFormValues({ ...formValues, imageUrl: url });
        setShowLibrary(false);
    };

    return (
        <>
            <Form.Group controlId="formCardTitle">
                <Form.Label>Card Title</Form.Label>
                <Form.Control
                    type="text"
                    name="title"
                    value={formValues.title || ''}
                    onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                />
            </Form.Group>

            <Form.Group controlId="formCardImageUrl" className="mt-3">
                <Form.Label>Image</Form.Label>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {formValues.imageUrl ? (
                        <Image 
                            src={formValues.imageUrl} 
                            alt="Selected Image" 
                            thumbnail 
                            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                        />
                    ) : (
                        <div style={{ width: '100px', height: '100px', backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            No Image Selected
                        </div>
                    )}
                </div>
                
                <MediaLibrary
                    onSelectImage={handleImageSelect}
                />
            </Form.Group>

            <Form.Group controlId="formCardBodyText" className="mt-3">
                <Form.Label>Body Text</Form.Label>
                <Form.Control
                    type="text"
                    name="bodyText"
                    value={formValues.bodyText || ''}
                    onChange={(e) => setFormValues({ ...formValues, bodyText: e.target.value })}
                />
            </Form.Group>

        </>
    );
};

export default ImageOverlayCardForm;
