import React from 'react';

function AudioComponent({ src }) {
    return (
        <audio controls>
            <source src={src || 'https://www.w3schools.com/html/horse.ogg'} type="audio/ogg" />
            <source src={src || 'https://www.w3schools.com/html/horse.mp3'} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    );
}

export default AudioComponent;
