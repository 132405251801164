import React, { useReducer, useCallback, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DraggableComponent from './component/DraggableComponent';
import DroppableColumn from './component/DroppableColumn';
import ComponentEditorModal from './component/ComponentEditorModal';
import { exampleProps } from './config/exampleProps';
import DroppableRow from './component/droppable/DroppableRow';
import DroppableSection from './component/droppable/DroppableSection';
import DroppableSubSection from './component/droppable/DroppableSubSection';
import pageBuilderReducer from './reducers/pageBuilderReducer';

import {
    faFont,
    faImage,
    faTableCellsLarge,
    faAddressCard,
    faCircleInfo,
    faHeading,
    faQuoteRight,
    faVideo,
    faTh,
    faColumns,
    faMinus,
    faList,
    faExclamationTriangle,
    faCheckSquare,
    faDotCircle,
    faMusic,
    faCode,
    faFolder,
    faLayerGroup,
    faImages,
    faWindowRestore,
    faArrowsAltV,
    faTag,
    faPlus,
    faSpinner,
    faTrashCan,
    faDollarSign,
    faMapMarkerAlt,
    faQuestionCircle,
    faShareAlt,
    faClock,
    faStream,
    faUsers,
    faStar,
    faArrowsUpDownLeftRight,
    faEyeSlash,
    faEye,
    faClone,
    faAngleRight,
    faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../config';

import { componentTypes } from './config/componentTypes';


const columnLayouts = {
    fullWidth: ['col-md-12'],
    leftSmallRightBig: ['col-md-3 col-sm-12', 'col-md-9 col-sm-12'],
    leftBigRightSmall: ['col-md-9 col-sm-12', 'col-md-3 col-sm-12'],
    twoEqualColumns: ['col-md-6 col-sm-12', 'col-md-6 col-sm-12'],
    threeEqualColumns: ['col-md-4 col-sm-12', 'col-md-4 col-sm-12', 'col-md-4 col-sm-12'],
    fourEqualColumns: ['col-md-3 col-sm-12', 'col-md-3 col-sm-12', 'col-md-3 col-sm-12', 'col-md-3 col-sm-12'],
    sixEqualColumns: ['col-md-2 col-sm-12', 'col-md-2 col-sm-12', 'col-md-2 col-sm-12', 'col-md-2 col-sm-12', 'col-md-2 col-sm-12', 'col-md-2 col-sm-12']
};


const initialState = { rows: [] };

const fetchPageData = async (pageId) => {
    const response = await fetch(`${API_URL}/pages-builder/${pageId}`);
    const data = await response.json();
    return data;
};


function PageBuilder() {
    const [loading, setLoading] = useState(false);
    const [{ rows }, dispatch] = useReducer(pageBuilderReducer, initialState);
    const [modalShow, setModalShow] = useState(false);
    const [previewMode, setPreviewMode] = useState(false);
    const [currentComponent, setCurrentComponent] = useState(null);
    const [currentSectionId, setCurrentSectionId] = useState(null);
    const [currentColumnIdx, setCurrentColumnIdx] = useState(null);
    const [currentComponentIdx, setCurrentComponentIdx] = useState(null);
    const [currentSubSectionId, setCurrentSubSectionId] = useState(null);

    const { pageId } = useParams();
    const [dropResetFlags, setDropResetFlags] = useState({}); // Track reset flags for each column

    const addRow = useCallback(() => {
        dispatch({ type: 'ADD_ROW' });
    }, []);

    useEffect(() => {
        if (pageId) {
            fetchPageData(pageId).then(pageData => {
                dispatch({ type: 'LOAD_PAGE_DATA', payload: pageData });
            });
        }
    }, [pageId]);

    const addColumnInColumn = (rowId, sectionId, columnIdx, layoutKey) => {
        const newColumns = columnLayouts[layoutKey]; // Get the column layout based on the layout key
    
        dispatch({
            type: 'ADD_COLUMN_IN_COLUMN',
            payload: {
                rowId,         // Row where the section is located
                sectionId,     // Section ID inside the row
                columnIdx,     // Column index where the new section will be added
                newColumns     // New columns for the section
            }
        });
    };
    
    const updateColumnsInRow = useCallback((rowId, sectionId, layoutKey) => {
        const columns = columnLayouts[layoutKey];
        dispatch({ type: 'UPDATE_COLUMNS_IN_SECTION', payload: { rowId, sectionId, columns } });
    }, []);

    const changeLayout = useCallback((rowId, sectionId, layoutKey,subSection) => {
        const columns = columnLayouts[layoutKey];
    
        // Check if columns exist before dispatching
        if (!columns) {
            return; // Exit early if columns are not defined
        }
    
        dispatch({ 
            type: 'CHANGE_LAYOUT', 
            payload: { rowId, sectionId, columns,subSection } 
        });
    }, [dispatch, columnLayouts]);

    

    const changeSubLayout = useCallback((rowId, sectionId, layoutKey,subSection) => {
        const columns = columnLayouts[layoutKey];
    
        if (!columns) {
            return; // Exit early if columns are not defined
        }
    
        dispatch({ 
            type: 'CHANGE_SUB_SECTION_LAYOUT', 
            payload: { rowId, sectionId, columns,subSection } 
        });
    }, [dispatch, columnLayouts]);
    
    const removeColumnFromSection = useCallback((rowId, sectionId, columnIndex) => {
        dispatch({ type: 'REMOVE_COLUMN_FROM_SECTION', payload: { rowId, sectionId, columnIndex } });
    }, []);
    
    const updateSectionInRow = useCallback((rowId, layoutKey) => {
        const columns = columnLayouts[layoutKey];
        dispatch({ type: 'UPDATE_SECTION_IN_ROW', payload: { rowId, columns } });
    }, []);

    const addComponentToColumn = useCallback((rowId, targetSectionId, targetColumnIdx, item) => {
        const { type, sectionId, columnIdx, componentIdx, props } = item;

        const isMainToSub = sectionId !== undefined && columnIdx !== undefined && targetSectionId === undefined;

        if (sectionId !== undefined && columnIdx !== undefined && targetSectionId !== sectionId) {
            removeSubComponent(rowId, targetSectionId, sectionId, columnIdx, componentIdx, isMainToSub);
        } else if (sectionId !== undefined && columnIdx !== undefined) {
            removeComponent(rowId, sectionId, columnIdx, componentIdx, isMainToSub);
        }
    
        const component = { type, props };
    
        // Dispatch action to add component to the new target column
        dispatch({
            type: 'ADD_COMPONENT_TO_COLUMN',
            payload: { rowId, sectionId: targetSectionId, columnIdx: targetColumnIdx, component }
        });
    }, [dispatch]);

    const addSubComponentToColumn = useCallback((rowId, sectionId, columnIdx, item, sectionColId,subSectionId, subColIdx) => {
        const { type, componentIdx, props } = item;
        console.log(item)
        const fromsectionId = item.sectionId;
        const columnIndex = item.columnIdx;
        const deleteId = `${fromsectionId}_${columnIndex}`;

        const isMainToSub = subSectionId !== undefined && subSectionId !== sectionId && subSectionId !== fromsectionId && fromsectionId == sectionId;

        console.log(columnIndex,subColIdx)

        if (subColIdx !== undefined && componentIdx !== undefined ) {
            removeSubComponent(rowId, sectionId, subSectionId, columnIndex, componentIdx, isMainToSub,fromsectionId); 
        } else if (sectionId !== undefined && columnIdx !== undefined) {
            removeComponent(rowId, sectionId, columnIdx, componentIdx, isMainToSub);
        }
    
        // Create the new sub-component object
        const component = { type, props };
    
        // Dispatch action to add the sub-component to the specified sub-column
        dispatch({
            type: 'ADD_COMPONENT_TO_SUB_COLUMN',
            payload: {
                rowId,
                sectionId,
                columnIdx,
                component,
                subSectionId,
                subColIdx,
                sectionColId
            }
        });

        if (subSectionId) {
            setTimeout(() => {
                handleResetDrop(subSectionId);
            }, 1000);
        }
    }, [dispatch]);
    
    const removeRow = useCallback((rowId) => {
        dispatch({ type: 'REMOVE_ROW', payload: rowId });
    }, []);

    const removeSection = useCallback((rowId, sectionId, subSectionId) => {
        dispatch({ type: 'REMOVE_SECTION', payload: { rowId, sectionId, subSectionId} });
    }, []);

    const removeSubSection = useCallback((rowId, sectionId, subSectionId) => {
        dispatch({ type: 'REMOVE_SUB_SECTION', payload: { rowId, sectionId, subSectionId} });
    }, []);

    const addSectionAtPosition = useCallback((rowId, position) => {
        const columns = columnLayouts.fullWidth;
        dispatch({ type: 'ADD_SECTION_AT_POSITION', payload: { rowId, columns, position } });
    }, []);

    const moveRowToAnotherSection = (fromRowId, toRowId, rowIndex) => {
        dispatch({
            type: 'MOVE_ROW_TO_ANOTHER_SECTION',
            payload: {
                fromRowId,
                toRowId,
                rowIndex
            }
        });
    };

    const savePage = useCallback(() => {
        setLoading(true); // Start loading before sending request
    
        const pageData = {
            title: 'My New Page',
            pageId: pageId,
            rows: rows.map(row => ({
                disabled: row.disabled,
                sections: row.sections.map(section => ({
                    disabled: section.disabled,
                    layoutKey: section.columns.map(col => col.class).join('-'),
                    columns: section.columns.map(col => ({
                        class: col.class,
                        disabled: col.disabled,
                        components: col.components.map(component => ({
                            type: component.type,
                            props: component.props,
                            disabled: component.disabled
                        })),
                        subsections: col.sections?.map(nestedSection => ({
                            layoutKey: nestedSection.columns.map(nestedCol => nestedCol.class).join('-'),
                            disabled: nestedSection.disabled,
                            subcolumns: nestedSection.columns.map(nestedCol => ({
                                class: nestedCol.class,
                                disabled: nestedCol.disabled,
                                components: nestedCol.components.map(nestedComponent => ({
                                    type: nestedComponent.type,
                                    props: nestedComponent.props,
                                    disabled: nestedComponent.disabled
                                }))
                            }))
                        })) || []
                    }))
                }))
            }))
        };
    
        fetch(`${API_URL}/pages-builder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(pageData)
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false); // Stop loading when the request completes
            })
            .catch(error => {
                setLoading(false); // Stop loading in case of error
            });
    }, [rows, pageId]);
    

    const handleEditComponent = (sectionId, columnIdx, componentIdx, component) => {
        setCurrentComponent(component);
        setCurrentSectionId(sectionId);
        setCurrentColumnIdx(columnIdx);
        setCurrentComponentIdx(componentIdx);
        setModalShow(true);
    };

    const saveChanges = (newProps) => {
        const updatedComponent = { ...currentComponent, props: newProps };

        console.log(newProps);
        console.log(currentColumnIdx);
        console.log(updatedComponent);
        

        dispatch({
            type: 'UPDATE_COMPONENT_IN_COLUMN',
            payload: {
                sectionId: currentSectionId,
                columnIdx: currentColumnIdx,
                componentIdx: currentComponentIdx,
                updatedComponent
            }
        });
        setModalShow(false);
    };

    const moveRow = (dragIndex, hoverIndex) => {
        dispatch({
            type: 'MOVE_ROW',
            payload: {
                fromRowIndex: dragIndex,
                toRowIndex: hoverIndex
            }
        });
    };

    const moveSection = (rowId, fromSectionIndex, toSectionIndex) => {
        dispatch({
            type: 'MOVE_SECTION',
            payload: {
                rowId,
                fromSectionIndex,
                toSectionIndex
            }
        });
    };

    const moveSubSection = (rowId, id,columnIdx, fromSubSectionIndex, toSubSectionIndex) => {
        dispatch({
            type: 'MOVE_SUB_SECTION',
            payload: {
                rowId,
                id,
                columnIdx,
                fromSubSectionIndex,
                toSubSectionIndex
            }
        });
    };
    

    const moveComponent = (fromSectionId, toSectionId, fromColumnIdx, toColumnIdx, fromComponentIdx, toComponentIdx) => {
        dispatch({
            type: 'MOVE_COMPONENT',
            payload: {
                fromSectionId,
                toSectionId,
                fromColumnIdx,
                toColumnIdx,
                fromComponentIdx,
                toComponentIdx
            }
        });
    };

    const cloneRow = (rowId) => {
        dispatch({
            type: 'CLONE_ROW',
            payload: {
                rowId
            }
        });
    };

    const [isDisabled, setIsDisabled] = useState(false); 
    
    const toggleRowState = (rowId) => {
        dispatch({
            type: 'DISABLE_ROW',
            payload: {
                rowId
            }
        });
    };

    const toggleSectionState = (rowId, sectionId) => {
        dispatch({
            type: 'DISABLE_SECTION',
            payload: {
                rowId,
                sectionId
            }
        });
    };

    

    const toggleSubSectionState = (rowId, sectionId,subSectionId ,colIdx) => {
        dispatch({
            type: 'DISABLE_SUB_SECTION',
            payload: {
                rowId,
                sectionId,
                subSectionId,
                colIdx
            }
        });
    };

    const cloneSection = (rowId, sectionId) => {
        dispatch({
            type: 'CLONE_SECTION',
            payload: {
                rowId,
                sectionId
            }
        });
    };

    const cloneNestedSection = (rowId, sectionId,subSectionId ,colIdx) => {
        dispatch({
            type: 'CLONE_NESTED_SECTION',
            payload: {
                rowId,
                sectionId,
                subSectionId,
                colIdx
            }
        });
    }

    const removeComponent = (rowID, sectionId, columnIdx, componentIdx, isMainToSub = false) => {
        dispatch({
            type: 'REMOVE_COMPONENT_FROM_COLUMN',
            payload: {
                rowID,
                sectionId,
                columnIdx,
                componentIdx,
                isMainToSub // Pass the flag to the reducer
            }
        });
    };

    const removeSubComponent = (rowID, sectionId, subSectionId, columnIdx, componentIdx, isMainToSub = false,fromsectionId= '') => {
        // console.log(rowID, sectionId, subSectionId, columnIdx, componentIdx, isMainToSub);
        
        dispatch({
            type: 'REMOVE_SUB_COMPONENT_FROM_COLUMN',
            payload: {
                rowID,
                sectionId,
                subSectionId,
                columnIdx,
                componentIdx,
                isMainToSub,
                fromsectionId 
            }
        });
    };

    const handleRemove = (removeFunction, params) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                popup: 'swal2-popup-custom', // Add custom class if needed
            }
        }).then((result) => {
            if (result.isConfirmed) {
                removeFunction(...params); // Call the removal function with parameters
    
                // Show success message
                Swal.fire({
                    title: 'Deleted!',
                    text: 'The item has been removed.',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom', // Add custom class if needed
                    }
                });
            }
        });
    };
    
    
    const [disabledColumns, setDisabledColumns] = useState({}); // State to track disabled columns

    // Function to handle button click and hide the actions-wrap for the specific column
    const handleButtonClick = (rowId, sectionId, columnIdx, layoutKey) => {
        addColumnInColumn(rowId, sectionId, columnIdx, layoutKey);
        setDisabledColumns(prevState => ({
            ...prevState,
            [columnIdx]: true 
        }));
    };

    const handleResetDrop = (id) => {
        setDropResetFlags((prev) => ({ ...prev, [id]: true })); // Mark the column for reset
        // You might want to clear the reset flag after some time or after the next drop
    };


    const togglePreviewMode = () => {
        setPreviewMode(!previewMode);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="container-fluid">
                <div className="row builder-wrap">
                    {!previewMode && (
                        <div className="col-md-2 left-col">
                            <div className="components-list">

                                <h3><span>Basic Widgets</span></h3>
                                <div className="dragable-main">
                                    <DraggableComponent type="heading" props={exampleProps.heading}><FontAwesomeIcon icon={faHeading} /> Heading</DraggableComponent>
                                    <DraggableComponent type="text" props={exampleProps.text}><FontAwesomeIcon icon={faFont} /> Text</DraggableComponent>
                                    <DraggableComponent type="richText" props={exampleProps.richText}><FontAwesomeIcon icon={faFont} /> Rich Text</DraggableComponent>
                                    <DraggableComponent type="image" props={exampleProps.image}><FontAwesomeIcon icon={faImage} /> Image</DraggableComponent>
                                    <DraggableComponent type="button" props={exampleProps.button}><FontAwesomeIcon icon={faFont} /> Button</DraggableComponent>
                                    <DraggableComponent type="blockquote" props={exampleProps.blockquote}><FontAwesomeIcon icon={faQuoteRight} /> Blockquote</DraggableComponent>
                                    <DraggableComponent type="divider" props={exampleProps.divider}><FontAwesomeIcon icon={faMinus} /> Divider</DraggableComponent>
                                    <DraggableComponent type="alert" props={exampleProps.alert}><FontAwesomeIcon icon={faExclamationTriangle} /> Alert</DraggableComponent>
                                </div>
                                <h3><span>Media Widgets</span></h3>
                                <div className="dragable-main">
                                    <DraggableComponent type="video" props={exampleProps.video}><FontAwesomeIcon icon={faVideo} /> Video</DraggableComponent>
                                    <DraggableComponent type="audio" props={exampleProps.audio}><FontAwesomeIcon icon={faMusic} /> Audio</DraggableComponent>
                                    <DraggableComponent type="iframe" props={exampleProps.iframe}><FontAwesomeIcon icon={faCode} /> Iframe</DraggableComponent>
                                    <DraggableComponent type="gallery" props={exampleProps.gallery}><FontAwesomeIcon icon={faTh} /> Gallery</DraggableComponent>
                                    <DraggableComponent type="lightboxGallery" props={exampleProps.lightboxGallery}><FontAwesomeIcon icon={faImages} /> Lightbox Gallery</DraggableComponent>
                                </div>
                                <h3><span>Layout Widgets</span></h3>
                                <div className="dragable-main">
                                    <DraggableComponent type="container"><FontAwesomeIcon icon={faColumns} /> Container</DraggableComponent>
                                    <DraggableComponent type="tabs" props={exampleProps.tabs}><FontAwesomeIcon icon={faFolder} /> Tabs</DraggableComponent>
                                    <DraggableComponent type="accordion" props={exampleProps.accordion}><FontAwesomeIcon icon={faLayerGroup} /> Accordion</DraggableComponent>
                                    <DraggableComponent type="carousel" props={exampleProps.carousel}><FontAwesomeIcon icon={faImages} /> Carousel</DraggableComponent>
                                    <DraggableComponent type="modal" props={exampleProps.modal}><FontAwesomeIcon icon={faWindowRestore} /> Modal</DraggableComponent>
                                    <DraggableComponent type="spacer" props={exampleProps.spacer}><FontAwesomeIcon icon={faArrowsAltV} /> Spacer</DraggableComponent>
                                    <DraggableComponent type="badge" props={exampleProps.badge}><FontAwesomeIcon icon={faTag} /> Badge</DraggableComponent>
                                </div>
                                <h3><span>Card Widgets</span></h3>
                                <div className="dragable-main">
                                    <DraggableComponent type="cardComponent" props={exampleProps.cardComponent}><FontAwesomeIcon icon={faAddressCard} /> Card</DraggableComponent>
                                    <DraggableComponent type="imageOverlayCard" props={exampleProps.imageOverlayCard}><FontAwesomeIcon icon={faImage} /> Image Overlay Card</DraggableComponent>
                                    <DraggableComponent type="headerFooterCard" props={exampleProps.headerFooterCard}><FontAwesomeIcon icon={faAddressCard} /> Header Footer Card</DraggableComponent>
                                    <DraggableComponent type="horizontalCard" props={exampleProps.horizontalCard}><FontAwesomeIcon icon={faArrowsUpDownLeftRight} /> Horizontal Card</DraggableComponent>
                                    <DraggableComponent type="listGroupCard" props={exampleProps.listGroupCard}><FontAwesomeIcon icon={faList} /> List Group Card</DraggableComponent>
                                    <DraggableComponent type="iconCard" props={exampleProps.iconCard}><FontAwesomeIcon icon={faArrowsUpDownLeftRight} /> Icon Card</DraggableComponent>

                                </div>
                                <h3><span>Business Widgets</span></h3>
                                <div className="dragable-main">
                                    <DraggableComponent type="categoryList" props={exampleProps.categoryList}><FontAwesomeIcon icon={faList} /> Category List</DraggableComponent>
                                    <DraggableComponent type="productList" props={exampleProps.productList}><FontAwesomeIcon icon={faTh} /> Product List</DraggableComponent>
                                    <DraggableComponent type="pricingTable" props={exampleProps.pricingTable}><FontAwesomeIcon icon={faDollarSign} /> Pricing Table</DraggableComponent>
                                    <DraggableComponent type="map" props={exampleProps.map}><FontAwesomeIcon icon={faMapMarkerAlt} /> Map</DraggableComponent>
                                    <DraggableComponent type="faq" props={exampleProps.faq}><FontAwesomeIcon icon={faQuestionCircle} /> FAQ</DraggableComponent>
                                    <DraggableComponent type="socialMediaIcons" props={exampleProps.socialMediaIcons}><FontAwesomeIcon icon={faShareAlt} /> Social Media Icons</DraggableComponent>
                                    <DraggableComponent type="countdownTimer" props={exampleProps.countdownTimer}><FontAwesomeIcon icon={faClock} /> Countdown Timer</DraggableComponent>
                                    <DraggableComponent type="timeline" props={exampleProps.timeline}><FontAwesomeIcon icon={faStream} /> Timeline</DraggableComponent>
                                    <DraggableComponent type="featureCards" props={exampleProps.featureCards}><FontAwesomeIcon icon={faStar} /> Feature Cards</DraggableComponent>
                                    <DraggableComponent type="teamSection" props={exampleProps.teamSection}><FontAwesomeIcon icon={faUsers} /> Team Section</DraggableComponent>
                                </div>

                            </div>
                        </div>
                    )}
                    <div className={`col-md-10 page-builder-right ${previewMode ? 'preview-mode col-md-12' : ''}`}>
                        {previewMode ? (
                            <div className="preview-mode">
                           {rows.map((row, index) => {
                            if (row.disabled === true || row.disabled === 1) {
                                return null; // Skip rendering this row if disabled
                            }

                            return (
                                <div key={row.id} className="mt-3 section-wrap">
                                    {row.sections.map((section, sectionIndex) => {

                                        // Check if the section is disabled (true or 1)
                                        if (section.disabled === true || section.disabled === 1) {
                                            return null; // Skip rendering this section if disabled
                                        }

                                        return (
                                            <div key={section.id} className={`section ${row.disabled ? 'disabled' : ''}`}>
                                                <div className="row">
                                                    {section.columns.map((column, idx) => {

                                                        return (
                                                            <div key={idx} className={`${column.class} p-3`}>
                                                                {/* Log components */}
                                                                {column.components.map((comp, compIdx) => {
                                                                    return (
                                                                        <div key={compIdx}>
                                                                            {React.createElement(componentTypes[comp.type], { ...comp.props })}
                                                                        </div>
                                                                    );
                                                                })}

                                                                {/* Render sections if they exist */}
                                                                {column.sections && column.sections.length > 0 && column.sections.map((subsection, subsectionIndex) => {
                                                                    // Check if the subsection is disabled
                                                                    if (subsection.disabled === true || subsection.disabled === 1) {
                                                                        return null; // Skip rendering this subsection if disabled
                                                                    }

                                                                    return (
                                                                        <div key={subsection.id}>
                                                                            <div className="row">
                                                                                {subsection.columns.map((subcolumn, subcolumnIdx) => {

                                                                                    return (
                                                                                        <div key={subcolumnIdx} className={`${subcolumn.class} p-3`}>
                                                                                            {subcolumn.components.map((subcomp, subcompIdx) => { // Log subcomponent data
                                                                                                return (
                                                                                                    <div key={subcompIdx}>
                                                                                                        {React.createElement(componentTypes[subcomp.type], { ...subcomp.props })}
                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                            })}

                            </div>

                        ) : (
                            rows.map((row, index) => (
                                <DroppableRow
                                    key={row.id}
                                    index={index}
                                    moveRow={moveRow}
                                    onRemoveRow={() => removeRow(row.id)}
                                    onCloneRow={() => cloneRow(row.id)}
                                >
                                <div className="mb-2 p-2 pb-5 added-row">
                                    <div className="actions-wrap">
                                        <button className="drag-handle">
                                            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                                        </button>
                                        <button onClick={() => toggleRowState(row.id)}>
                                            <FontAwesomeIcon icon={row.disabled ? faEyeSlash : faEye} />
                                        </button>
                                        <button onClick={() => cloneRow(row.id)}><FontAwesomeIcon icon={faClone} /></button>
                                        <button onClick={() => handleRemove(removeRow, [row.id])}>
                                            <FontAwesomeIcon icon={faTrashCan} />
                                        </button>
                                    </div>
                                    <div className="grid-selection">
                                        {Object.keys(columnLayouts).map(key => (
                                            <button
                                                key={key}
                                                className="btn btn-outline-secondary grid-icon"
                                                onClick={() => updateSectionInRow(row.id, key)}
                                            >
                                                <div className="grid-inner-wrap">
                                                    {columnLayouts[key].map((colClass, idx) => (
                                                        <div key={idx} className={`${colClass} grid-inner`}></div>
                                                    ))}
                                                </div>
                                            </button>
                                        ))}
                                    </div>
                                    <div className={`row ${row.disabled ? 'disabled-row' : ''}`}>
                                        {row.sections.map((section, sectionIndex) => (
                                            <DroppableSection
                                                key={section.id}
                                                id={section.id}
                                                rowId={row.id}
                                                index={sectionIndex}
                                                moveSection={moveSection}
                                                onRemoveSection={() => removeSection(row.id, section.id)}
                                                onCloneSection={() => cloneSection(row.id, section.id)}
                                            >
                                                <div className="applied-grid">
                                                    <div className="actions-wrap">
                                                        <button className="drag-handle"><FontAwesomeIcon icon={faArrowsUpDownLeftRight} /></button>
                                                        <button onClick={() => toggleSectionState(row.id, section.id)}>
                                                            <FontAwesomeIcon icon={section.disabled ? faEyeSlash : faEye} />
                                                        </button>
                                                        <button onClick={() => cloneSection(row.id, section.id)}><FontAwesomeIcon icon={faClone} /></button>
                                                        <button onClick={() => handleRemove(removeSection, [row.id, section.id])}>
                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                        </button>

                                                        {/* Dropdown for changing layout */}
                                                        <DropdownButton id="dropdown-basic-button" className="c" title={<svg
                                                            viewBox="0 0 28 28" preserveAspectRatio="xMidYMid meet" shapeRendering="geometricPrecision" style={{ fill: 'rgb(255, 255, 255)', width: '28px',minWidth: '28px',height: '28px',margin: '-6px',opacity: 1,transform: 'scale(1)'}}> <g> <path d="M20 8H8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 0 0-1-.999V8zm-7 2h2v8h-2v-8zm-2 8H9v-8h2v8zm6-8h2v8h-2v-8z" fillRule="evenodd"/>
                                                            </g></svg> }>
                                                            <Dropdown.Item>
                                                                <div className="grid-selection">
                                                                    {Object.keys(columnLayouts).map(layoutKey => (
                                                                        <button
                                                                            key={layoutKey}
                                                                            className="btn btn-outline-secondary grid-icon btn-color"
                                                                            onClick={() => changeLayout(row.id, section.id, layoutKey)}
                                                                        >
                                                                            <div className="grid-inner-wrap">
                                                                                {columnLayouts[layoutKey].map((colClass, idx) => (
                                                                                    <div key={idx} className={`${colClass} grid-inner`}></div>
                                                                                ))}
                                                                            </div>
                                                                        </button>
                                                                    ))}
                                                                </div>
                                                            </Dropdown.Item>
                                                        </DropdownButton>
                                                    </div>
                                                    {/* Render the columns */}
                                                   
                                                    <div className={`row ${section.disabled ? 'disabled-section' : ''}`}>
                                                        {section.columns.map((column, colIdx) => (
                                                            <DroppableColumn
                                                                key={colIdx}
                                                                id={`${section.id}_${colIdx}`}
                                                                className={`${column.class} p-3 border`}
                                                                onDropComponent={(columnId, item) => addComponentToColumn(row.id, section.id, colIdx, item, `${section.id}_${colIdx}`)}
                                                                resetDrop={dropResetFlags[`${section.id}_${colIdx}`]}
                                                            >
                                                                {/* first column */}
                                                                <div>
                                                                    {/* Check if there are no subsections in the current column */}
                                                                    {!column.sections || column.sections.length === 0 ? ( // No subsections
                                                                        <div className="actions-wrap">
                                                                            <div className="grid-selection">
                                                                                {Object.keys(columnLayouts).map(layoutKey => (
                                                                                    <button
                                                                                        key={layoutKey}
                                                                                        className="btn btn-outline-secondary grid-icon btn-color"
                                                                                        onClick={() => handleButtonClick(row.id, section.id, colIdx, layoutKey)} // Disable the button and hide the actions-wrap when clicked
                                                                                    >
                                                                                        <div className="grid-inner-wrap">
                                                                                            {columnLayouts[layoutKey].map((colClass, colIdx) => (
                                                                                                <div key={colIdx} className={`${colClass} grid-inner`}></div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </button>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    ) : null} {/* Actions wrap visible only if there are no subsections */}
                                                                </div>
                                                                {/* Render the components inside the first column */}
                                                                {column.components.map((comp, compIdx) => (
                                                                    <DraggableComponent
                                                                        key={compIdx}
                                                                        type={comp.type}
                                                                        sectionId={section.id}
                                                                        columnIdx={colIdx}
                                                                        rowID={row.id}
                                                                        componentIdx={compIdx}
                                                                        props={comp.props}
                                                                    >
                                                                        {React.createElement(componentTypes[comp.type], {
                                                                            ...comp.props,
                                                                            onSave: (newContent) => saveChanges({ ...comp.props, content: newContent })
                                                                        })}
                                                                        <div className="editable-wrap">
                                                                            <button className="btn" onClick={() => handleEditComponent(section.id, colIdx, compIdx, comp)}>
                                                                                <FontAwesomeIcon icon={faPenToSquare} />
                                                                            </button>
                                                                            <button className="btn" onClick={() => removeComponent(row.id,section.id, colIdx, compIdx)}>
                                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                                            </button>
                                                                        </div>
                                                                    </DraggableComponent>
                                                                ))}

                                                                {/* sections inside the column */}
                                                                {column.sections && column.sections.length > 0 && (
                                                                    <div className="row">
                                                                        {/* Nested sections */}
                                                                        {column.sections.map((subSection, subIdx) => (
                                                                            <DroppableSubSection
                                                                                key={subIdx}
                                                                                id={subSection.id}
                                                                                rowId={section.id}
                                                                                columnIdx={colIdx}
                                                                                index={subIdx}
                                                                                moveSubSection={moveSubSection}
                                                                                onRemoveSection={() => removeSubSection(row.id,section.id, subSection.id)}
                                                                                onCloneSection={() => cloneNestedSection(section.id, subSection.id)}
                                                                            >
                                                                                <div className="applied-grid">
                                                                                    <div className="actions-wrap">
                                                                                        <button className="drag-handle"><FontAwesomeIcon icon={faArrowsUpDownLeftRight} /></button>
                                                                                        <button onClick={() => toggleSubSectionState(row.id, section.id, subSection.id,colIdx)}>
                                                                                            <FontAwesomeIcon icon={subSection.disabled ? faEyeSlash : faEye} />
                                                                                        </button>
                                                                                        <button onClick={() => cloneNestedSection(row.id, section.id,subSection.id,colIdx)}><FontAwesomeIcon icon={faClone} /></button>
                                                                                        <button onClick={() => handleRemove(removeSubSection, [row.id, section.id, subSection.id])}>
                                                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                                                        </button>

                                                                                        {/* Dropdown for changing layout */}
                                                                                        <DropdownButton id="dropdown-basic-button" className="c" title={<FontAwesomeIcon icon={faTableCellsLarge} />}>
                                                                                            <Dropdown.Item>
                                                                                                <div className="grid-selection">
                                                                                                    {Object.keys(columnLayouts).map(layoutKey => (
                                                                                                        <button
                                                                                                            key={layoutKey}
                                                                                                            className="btn btn-outline-secondary grid-icon btn-color"
                                                                                                            onClick={() => changeSubLayout(row.id, section.id,layoutKey, subSection.id)}
                                                                                                        >
                                                                                                            <div className="grid-inner-wrap">
                                                                                                                {columnLayouts[layoutKey].map((colClass, idx) => (
                                                                                                                    <div key={idx} className={`${colClass} grid-inner`}></div>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </button>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </Dropdown.Item>
                                                                                        </DropdownButton>
                                                                                    </div>

                                                                                    {/* Render the columns inside the nested section */}
                                                                                    <div className={`row ${subSection.disabled ? 'disabled-subsection ' : ''}`}>
                                                                                        {subSection.columns.map((subColumn, subColIdx) => (
                                                                                            <DroppableColumn
                                                                                                key={subColIdx}
                                                                                                id={`${subSection.id}_${subColIdx}`}
                                                                                                className={`${subColumn.class} p-3 border`}
                                                                                                onDropComponent={(columnId, item) => addSubComponentToColumn(row.id,section.id, colIdx, item,`${subSection.id}_${subColIdx}`, subSection.id, subColIdx)}
                                                                                            >
                                                                                                {subColumn.components && Array.isArray(subColumn.components) ? (
                                                                                                    subColumn.components.map((comp, compIdx) => (
                                                                                                        comp ? (
                                                                                                            <DraggableComponent
                                                                                                                key={compIdx}
                                                                                                                type={comp.type}
                                                                                                                sectionId={subSection.id}
                                                                                                                rowID={row.id}
                                                                                                                columnIdx={subColIdx}
                                                                                                                componentIdx={compIdx}
                                                                                                                props={comp.props}
                                                                                                            >
                                                                                                                {React.createElement(componentTypes[comp.type], {
                                                                                                                    ...comp.props,
                                                                                                                    onSave: (newContent) => saveChanges({ ...comp.props, content: newContent })
                                                                                                                })}
                                                                                                                <div className="editable-wrap">
                                                                                                                    <button className="btn" onClick={() => handleEditComponent(subSection.id, subColIdx, compIdx, comp)}>
                                                                                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                                                                                    </button>
                                                                                                                    <button className="btn" onClick={() => removeSubComponent(row.id,section.id,subSection.id, subColIdx, compIdx)}>
                                                                                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </DraggableComponent>
                                                                                                        ) : null
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <p>No components available</p>
                                                                                                )}
                                                                                            </DroppableColumn>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            </DroppableSubSection>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </DroppableColumn>
                                                        ))}
                                                    </div>

                                                </div>
                                            </DroppableSection>
                                        ))}
                                    </div>
                                </div>
                                </DroppableRow>
                            ))
                        )}
                        {!previewMode && <button className="add-row-btn" onClick={addRow}><FontAwesomeIcon icon={faPlus} /> Add New Section</button>}
                        <div className="builder-footer">
                            <button className="btn btn-success" onClick={savePage} disabled={loading}>
                                {loading ? (
                                <>
                                    <FontAwesomeIcon icon={faSpinner} spin /> Saving...
                                </>
                                ) : (
                                <>
                                    <FontAwesomeIcon icon={faPlus} /> Save Page
                                </>
                                )}
                            </button>
                            <button className="btn btn-primary ml-2" onClick={togglePreviewMode}>
                                {previewMode ? 'Edit Mode' : 'Preview Mode'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ComponentEditorModal
                show={modalShow}
                handleClose={() => setModalShow(false)}
                component={currentComponent}
                saveChanges={saveChanges}
            />
        </DndProvider>
    );
}



export default PageBuilder;
