import React from 'react';

function TextComponent({ content, styles = {} }) {
    // Access styles from the nested "content" object
    const appliedStyles = styles.content || {}; 

    return (
        <div>
            <p style={appliedStyles}>{content || "Default Text"}</p>
        </div>
    );
}

export default TextComponent;
