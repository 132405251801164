import React, { useState } from 'react';

function AccordionComponent({ items }) {
    const [openIndexes, setOpenIndexes] = useState([]);

    const handleToggle = (index) => {
        setOpenIndexes((prev) => 
            prev.includes(index) 
                ? prev.filter((i) => i !== index) 
                : [...prev, index]
        );
    };

    return (
        <div className="accordion acc-full-width" id="accordionExample">
            {items && items.length > 0
                ? items.map((item, index) => (
                    <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                            <button 
                                className="accordion-button" 
                                type="button" 
                                onClick={() => handleToggle(index)} 
                                aria-expanded={openIndexes.includes(index)} 
                                aria-controls={`collapse${index}`}>
                                {item.title}
                            </button>
                        </h2>
                        <div 
                            id={`collapse${index}`} 
                            className={`accordion-collapse collapse ${openIndexes.includes(index) ? 'show' : ''}`} 
                            aria-labelledby={`heading${index}`} 
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {item.content}
                            </div>
                        </div>
                    </div>
                ))
                : <div className="accordion-item">
                    <h2 className="accordion-header" id="heading0">
                        <button 
                            className="accordion-button" 
                            type="button" 
                            onClick={() => handleToggle(0)} 
                            aria-expanded={openIndexes.includes(0)} 
                            aria-controls="collapse0">
                            Default Item
                        </button>
                    </h2>
                    <div 
                        id="collapse0" 
                        className={`accordion-collapse collapse ${openIndexes.includes(0) ? 'show' : ''}`} 
                        aria-labelledby="heading0" 
                        data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            Default Content
                        </div>
                    </div>
                </div>}
        </div>
    );
}

export default AccordionComponent;
