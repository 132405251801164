import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const SocialMediaIconsComponent = ({ links, styles = {} }) => (
    <div className="social-media-icons" style={styles.container}>
        {links.map((link, index) => {
            let icon;

            // Determine the icon based on the name
            switch (link.name) {
                case 'facebook':
                    icon = faFacebook;
                    break;
                case 'twitter':
                    icon = faTwitter;
                    break;
                case 'instagram':
                    icon = faInstagram;
                    break;
                case 'linkedin':
                    icon = faLinkedin;
                    break;
                default:
                    return null;
            }

            return (
                <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon-link"
                    style={styles.icon}
                >
                    <FontAwesomeIcon icon={icon} />
                </a>
            );
        })}
    </div>
);

export default SocialMediaIconsComponent;
