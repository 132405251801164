import React, { useEffect, useRef, useState } from 'react';
import loadGoogleMaps from './loadGoogleMaps';

const MapComponent = ({ location, zoom }) => {
    const mapRef = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [map, setMap] = useState(null);

    useEffect(() => {
        loadGoogleMaps('AIzaSyBYiozST1D4HSr3eZTpHfLQF14cfjvSOP4')
            .then(() => {
                setMapLoaded(true);
            })
            .catch((error) => {
                console.error('Error loading Google Maps:', error);
            });
    }, []);

    useEffect(() => {
        if (mapLoaded && window.google && window.google.maps) {
            const initializedMap = new window.google.maps.Map(mapRef.current, {
                center: location,
                zoom: zoom
            });

            new window.google.maps.Marker({
                position: location,
                map: initializedMap
            });

            setMap(initializedMap);
        }
    }, [location, zoom, mapLoaded]);

    useEffect(() => {
        if (map) {
            map.setCenter(location);
            map.setZoom(zoom);
        }
    }, [location, zoom, map]);

    return <div ref={mapRef} style={{ height: '400px', width: '100%' }} />;
};

export default MapComponent;
