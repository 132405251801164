// RichTextDisplay.js
import React, { useState } from 'react';
import RichTextEditor from './RichTextEditor';
import { Button } from 'react-bootstrap';

const RichTextDisplay = ({ content, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editorContent, setEditorContent] = useState(content);

    const handleSave = () => {
        onSave(editorContent);
        setIsEditing(false);
    };

    return (
        <div>
            {isEditing ? (
                <div>
                    <RichTextEditor value={editorContent} onChange={setEditorContent} />
                    <Button variant="primary" onClick={handleSave}>Save</Button>
                </div>
            ) : (
                <div
                    className="ql-editor ql-container ql-snow rich-text-wrap" // Add these classes to apply Quill styles
                    onClick={() => setIsEditing(true)}
                    dangerouslySetInnerHTML={{ __html: editorContent }}
                />
            )}
        </div>
    );
};

export default RichTextDisplay;
