import React, { useReducer, useCallback, useState, useEffect } from 'react';
import { Container, Carousel } from 'react-bootstrap';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableComponent from './component/DraggableComponent';
import DroppableColumn from './component/DroppableColumn';
import TextComponent from './component/pagebuilder/TextComponent';
import ImageComponent from './component/pagebuilder/ImageComponent';
import ButtonComponent from './component/pagebuilder/ButtonComponent';
import HeadingComponent from './component/pagebuilder/HeadingComponent';
import VideoComponent from './component/pagebuilder/VideoComponent';
import GalleryComponent from './component/pagebuilder/GalleryComponent';
import BlockquoteComponent from './component/pagebuilder/BlockquoteComponent';
import AboutUsComponent from './component/pagebuilder/AboutUsComponent';
import Loading from './component/Loading';  // Import the LoadingOverlay component

import BootstrapCardComponent from './component/pagebuilder/BootstrapCardComponent';
import ImageOverlayCard from './component/pagebuilder/ImageOverlayCard';
import HeaderFooterCard from './component/pagebuilder/HeaderFooterCard';
import HorizontalCard from './component/pagebuilder/HorizontalCard';
import IconCard from './component/pagebuilder/IconCard';

import ListGroupCard from './component/pagebuilder/ListGroupCard';

import DividerComponent from './component/pagebuilder/DividerComponent';
import ListComponent from './component/pagebuilder/ListComponent';
import AlertComponent from './component/pagebuilder/AlertComponent';
import InputComponent from './component/pagebuilder/InputComponent';
import TextareaComponent from './component/pagebuilder/TextareaComponent';
import SelectComponent from './component/pagebuilder/SelectComponent';
import CheckboxComponent from './component/pagebuilder/CheckboxComponent';
import RadioComponent from './component/pagebuilder/RadioComponent';
import AudioComponent from './component/pagebuilder/AudioComponent';
import IframeComponent from './component/pagebuilder/IframeComponent';
import TabsComponent from './component/pagebuilder/TabsComponent';
import AccordionComponent from './component/pagebuilder/AccordionComponent';
import CarouselComponent from './component/pagebuilder/CarouselComponent';
import ModalComponent from './component/pagebuilder/ModalComponent';
import SpacerComponent from './component/pagebuilder/SpacerComponent';
import BadgeComponent from './component/pagebuilder/BadgeComponent';
import PricingTableComponent from './component/pagebuilder/PricingTableComponent';
import MapComponent from './component/pagebuilder/MapComponent';
import FAQComponent from './component/pagebuilder/FAQComponent';
import SocialMediaIconsComponent from './component/pagebuilder/SocialMediaIconsComponent';
import CountdownTimerComponent from './component/pagebuilder/CountdownTimerComponent';
import TimelineComponent from './component/pagebuilder/TimelineComponent';
import LightboxGalleryComponent from './component/pagebuilder/LightboxGalleryComponent';
import FeatureCardsComponent from './component/pagebuilder/FeatureCardsComponent';
import TeamSectionComponent from './component/pagebuilder/TeamSectionComponent';
import CategoryList from './component/pagebuilder/CategoryList';
import ProductList from './component/pagebuilder/ProductList';
import ComponentEditorModal from './component/ComponentEditorModal';
import RichTextDisplay from './component/pagebuilder/RichTextDisplay';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFont,
    faImage,
    faAddressCard,
    faCircleInfo,
    faHeading,
    faQuoteRight,
    faVideo,
    faTh,
    faColumns,
    faMinus,
    faList,
    faExclamationTriangle,
    faCheckSquare,
    faDotCircle,
    faMusic,
    faCode,
    faFolder,
    faLayerGroup,
    faImages,
    faWindowRestore,
    faArrowsAltV,
    faTag,
    faPlus,
    faTrashCan,
    faDollarSign,
    faMapMarkerAlt,
    faQuestionCircle,
    faShareAlt,
    faClock,
    faStream,
    faStar,
    faUsers,
    faArrowsUpDownLeftRight,
    faClone,
    faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { API_URL } from '../config';

import { componentTypes } from './config/componentTypes';

const initialState = { rows: [] };


function reducer(state, action) {
    switch (action.type) {
        case 'LOAD_PAGE_DATA':
            return {
                ...state,
                rows: action.payload.page_builder_rows.map(row => ({
                    id: row.id,
                    disabled: row.disabled,
                    sections: row.page_builder_sections.map(section => ({
                        id: section.id,
                        disabled: section.disabled,
                        columns: section.page_builder_columns.map(column => ({
                            class: column.class,
                            components: column.page_builder_components.map(component => ({
                                type: component.type,
                                props: JSON.parse(component.props)
                            })),
                            subsections: column.page_builder_subsections.map(subsection => ({
                                id: subsection.id,
                                disabled: subsection.disabled,
                                subcolumns: subsection.page_builder_subcolumns.map(subcolumn => ({
                                    class: subcolumn.class,
                                    subcomponents: subcolumn.page_builder_subcomponents.map(subcomponent => ({
                                        type: subcomponent.type,
                                        props: JSON.parse(subcomponent.props)
                                    }))
                                }))
                            }))
                        }))
                    }))
                }))
            };
        default:
            return state;
    }
}

const fetchPageData = async (pageId) => {
    const response = await fetch(`${API_URL}/pages-builder/${pageId}`);
    const data = await response.json();
    console.log(data);
    return data;
};

function PageBuilder({ pageId: propPageId }) {
    const { pageId: paramPageId } = useParams();
    const pageId = propPageId || paramPageId;
    const [{ rows }, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setIsLoading] = useState(true);  // Loading state

    useEffect(() => {
        if (pageId) {
            setIsLoading(true);  // Start loading
            fetchPageData(pageId).then(pageData => {
                dispatch({ type: 'LOAD_PAGE_DATA', payload: pageData });
                setIsLoading(false);  // End loading once data is loaded
            });
        }
    }, [pageId]);

    // Separate carousel components based on isFullScreen flag
    const fullScreenCarousels = rows.flatMap(row =>
        row.sections.flatMap(section =>
            section.columns.flatMap(column =>
                column.components.filter(comp => comp.type === 'carousel' && comp.props.isFullScreen)
            )
        )
    );

    return (
        <div>
            {isLoading && <Loading />}  {/* Show LoadingOverlay while loading */}

            {/* Render full-screen carousels at the top */}
            {fullScreenCarousels.map((carouselComp, idx) => (
                <div key={idx} className="carousel-full-screen">
                    {React.createElement(componentTypes[carouselComp.type], { ...carouselComp.props })}
                </div>
            ))}

            {rows.map((row, index) => (
                row.disabled !== 1 &&
                row.sections.some(section =>
                    section.columns.some(column =>
                        column.components.some(comp => !(comp.type === 'carousel' && comp.props.isFullScreen))
                    )
                ) && (
                    <React.Fragment key={index}>
                        <div className="mt-3 section-wrap">
                            {row.sections.map((section) => (
                                section.disabled !== 1 && (
                                    <div className="container" key={section.id}>
                                        <div className="row">
                                            {section.columns.map((column, idx) => (
                                                <div key={idx} className={`${column.class} p-3`}>
                                                    {column.components.map((comp, compIdx) => {
                                                        // Skip full-screen carousels here
                                                        if (comp.type === 'carousel' && comp.props.isFullScreen) return null;
                                                        return (
                                                            <div key={compIdx}>
                                                                {React.createElement(componentTypes[comp.type], { ...comp.props })}
                                                            </div>
                                                        );
                                                    })}
                                                    {column.subsections.map((subsection) => (
                                                        subsection.disabled !== 1 && (
                                                            <div key={subsection.id} className="row">
                                                                {subsection.subcolumns.map((subcolumn, subcolumnidx) => (
                                                                    <div key={subcolumnidx} className={`${subcolumn.class} p-3`}>
                                                                        {subcolumn.subcomponents.map((subcomp, subcompIdx) => (
                                                                            <div key={subcompIdx}>
                                                                                {React.createElement(componentTypes[subcomp.type], { ...subcomp.props })}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    </React.Fragment>
                )
            ))}

        </div>
    );
}

export default PageBuilder;
