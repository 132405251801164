import React from 'react';
import { Form, Button } from 'react-bootstrap';

const FAQForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="mb-3">
                    {Object.keys(itemSchema).map((key) => (
                        <Form.Control
                            key={key}
                            type="text"
                            placeholder={key}
                            value={item[key]}
                            onChange={(e) => {
                                const updatedItems = [...formValues[fieldName]];
                                updatedItems[index][key] = e.target.value;
                                setFormValues({ ...formValues, [fieldName]: updatedItems });
                            }}
                        />
                    ))}
                    <Button variant="danger" onClick={() => handleRemoveJsonArrayItem(fieldName, index)}>Remove</Button>
                </div>
            ))}
            <Button variant="primary" onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)}>Add More</Button>
        </>
    );

    return <>{renderJsonArrayFields('faqs', { question: '', answer: '' })}</>;
};

export default FAQForm;
