import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export const exampleProps = {
    text: { content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    image: { imgSrc: 'https://via.placeholder.com/300' },
    button: { text: 'Click Me' },
    heading: { text: 'Lorem Ipsum Heading', level: '1' },
    video: { src: 'https://www.w3schools.com/html/mov_bbb.mp4' },
    blockquote: { quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', author: 'Author Name' },
    cardComponent: { title: 'Card Title', imageUrl: 'https://via.placeholder.com/300', bodyText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    imageOverlayCard: { title: 'Overlay Title', imageUrl: 'https://via.placeholder.com/300', bodyText: 'Overlay Body Text' },
    headerFooterCard: { header: 'Card Header', footer: 'Card Footer', title: 'Card Title', bodyText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    horizontalCard: { title: 'Horizontal Card Title', imageUrl: 'https://via.placeholder.com/300', bodyText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    listGroupCard: { title: 'List Group Title', items: ['Lorem ipsum dolor sit amet', 'Consectetur adipiscing elit', 'Sed do eiusmod tempor incididunt'] },
    divider: {},
    list: { items: ['Lorem ipsum dolor sit amet', 'Consectetur adipiscing elit', 'Sed do eiusmod tempor incididunt'] },
    alert: { type: 'warning', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    input: { placeholder: 'Enter text', value: '' },
    textarea: { placeholder: 'Enter multi-line text', value: '' },
    select: { options: [{ value: '1', label: 'Option 1' }, { value: '2', label: 'Option 2' }] },
    checkbox: { label: 'Check me', checked: false },
    radio: { name: 'exampleRadio', options: [{ value: '1', label: 'Option 1' }, { value: '2', label: 'Option 2' }] },
    audio: { src: 'https://www.w3schools.com/html/horse.mp3' },
    iframe: { src: 'https://www.example.com', height: '300', width: '100%' },
    tabs: { tabs: [{ key: 'tab1', title: 'Tab 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' }, { key: 'tab2', title: 'Tab 2', content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }] },
    accordion: { items: [{ title: 'Accordion Item 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' }, { title: 'Accordion Item 2', content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }] },
    carousel: { items: [{ src: 'https://via.placeholder.com/800x400', alt: 'Slide 1', caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' }, { src: 'https://via.placeholder.com/800x400', alt: 'Slide 2', caption: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }] },
    modal: { title: 'Modal Title', body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', buttonLabel: 'Open Modal' },
    spacer: { height: '20px' },
    badge: { text: 'New', variant: 'success' },
    gallery: { images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/200', 'https://via.placeholder.com/250'] },
    pricingTable: { plans: [{ name: 'Basic', price: '10', features: ['Lorem ipsum dolor sit amet', 'Consectetur adipiscing elit', 'Sed do eiusmod tempor incididunt'] }, { name: 'Pro', price: '20', features: ['Lorem ipsum dolor sit amet', 'Consectetur adipiscing elit', 'Sed do eiusmod tempor incididunt'] }, { name: 'Enterprise', price: '30', features: ['Lorem ipsum dolor sit amet', 'Consectetur adipiscing elit', 'Sed do eiusmod tempor incididunt'] }] },
    map: { location: { lat: 40.748817, lng: -73.985428 }, zoom: 12 },
    socialMediaIcons: {
        links: [
            { name: 'facebook', url: 'https://facebook.com', icon: faFacebook },
            { name: 'twitter', url: 'https://twitter.com', icon: faTwitter },
            { name: 'instagram', url: 'https://instagram.com', icon: faInstagram },
            { name: 'linkedin', url: 'https://linkedin.com', icon: faLinkedin }
        ]
    },
    countdownTimer: { targetDate: '2024-12-31T00:00:00' },
    timeline: { events: [{ title: 'Event 1', date: '2024-01-01', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' }, { title: 'Event 2', date: '2024-06-01', description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }] },
    lightboxGallery: { images: [{ src: 'https://via.placeholder.com/600', thumbnail: 'https://via.placeholder.com/150', caption: 'Image 1 - Lorem ipsum dolor sit amet' }, { src: 'https://via.placeholder.com/600', thumbnail: 'https://via.placeholder.com/150', caption: 'Image 2 - Consectetur adipiscing elit' }] },
    featureCards: { features: [{ image: 'https://via.placeholder.com/300', title: 'Feature 1', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' }, { image: 'https://via.placeholder.com/300', title: 'Feature 2', description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }] },
    teamSection: { teamMembers: [{ image: 'https://via.placeholder.com/300', name: 'John Doe', position: 'CEO' }, { image: 'https://via.placeholder.com/300', name: 'Jane Smith', position: 'CTO' }] },
    richText: { content: '<p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>.</p>' },
    categoryList: { location: { lat: 40.748817, lng: -73.985428 }, zoom: 12 },
    productList: { location: { lat: 40.748817, lng: -73.985428 }, zoom: 12 },
    iconCard: { icon: 'faStar', title: 'Icon Card Title', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    faq: {
        title: 'Frequently Asked Questions',
        faqs: [
            { question: 'What is your return policy?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
            { question: 'Do you offer technical support?', answer: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }
        ]
    }
};
