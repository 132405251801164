import React from 'react';

function RadioComponent({ name, options }) {
    return (
        <div>
            {options && options.length > 0
                ? options.map((option, index) => (
                    <div key={index} className="form-check">
                        <input type="radio" className="form-check-input" name={name} value={option.value} />
                        <label className="form-check-label">{option.label}</label>
                    </div>
                ))
                : <div className="form-check">
                    <input type="radio" className="form-check-input" name={name} />
                    <label className="form-check-label">Default Radio</label>
                </div>}
        </div>
    );
}

export default RadioComponent;
