import React from 'react';

function GalleryComponent({ images }) {
    return (
        <div className="gallery">
            {images.map((img, idx) => (
                <img key={idx} src={img} alt={`Gallery image ${idx + 1}`} />
            ))}
        </div>
    );
}

export default GalleryComponent;
