import React from 'react';

function SelectComponent({ options }) {
    return (
        <select className="form-control">
            {options && options.length > 0
                ? options.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)
                : <option>Default Option</option>}
        </select>
    );
}

export default SelectComponent;
