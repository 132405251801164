import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ImageOverlayCard = ({ title, imageUrl, bodyText, styles = {} }) => {
    const { 
        title: titleStyles = {}, 
        bodyText: bodyTextStyles = {}, 
        image: imageStyles = {}, 
        button: buttonStyles = {} 
    } = styles;

    const appliedTitleStyles = {
        fontFamily: titleStyles.fontFamily,
        fontSize: titleStyles.fontSize,
        color: titleStyles.color,
        fontWeight: titleStyles.fontWeight,
        textAlign: titleStyles.textAlign,
        marginTop: titleStyles.marginTop,
        marginRight: titleStyles.marginRight,
        marginBottom: titleStyles.marginBottom,
        marginLeft: titleStyles.marginLeft,
        letterSpacing: titleStyles.letterSpacing,
        lineHeight: titleStyles.lineHeight,
    };

    const appliedBodyTextStyles = {
        fontFamily: bodyTextStyles.fontFamily,
        fontSize: bodyTextStyles.fontSize,
        color: bodyTextStyles.color,
        fontWeight: bodyTextStyles.fontWeight,
        textAlign: bodyTextStyles.textAlign,
        lineHeight: bodyTextStyles.lineHeight,
        letterSpacing: bodyTextStyles.letterSpacing,
        marginTop: bodyTextStyles.marginTop,
        marginRight: bodyTextStyles.marginRight,
        marginBottom: bodyTextStyles.marginBottom,
        marginLeft: bodyTextStyles.marginLeft,
    };

    const appliedImageStyles = {
        height: imageStyles.height,
        width: imageStyles.width,
        opacity: imageStyles.opacity,
        filter: imageStyles.filter, // e.g., for blur effects
    };

    const appliedButtonStyles = {
        backgroundColor: buttonStyles.backgroundColor,
        color: buttonStyles.color,
        fontFamily: buttonStyles.fontFamily,
        fontSize: buttonStyles.fontSize,
        fontWeight: buttonStyles.fontWeight,
        paddingTop: buttonStyles.paddingTop,
        paddingRight: buttonStyles.paddingRight,
        paddingBottom: buttonStyles.paddingBottom,
        paddingLeft: buttonStyles.paddingLeft,
        borderRadius: buttonStyles.borderRadius,
        letterSpacing: buttonStyles.letterSpacing,
        lineHeight: buttonStyles.lineHeight,
    };

    return (
        <div className="card bg-dark text-white" style={{ position: 'relative' }}>
            <img src={imageUrl} className="card-img" alt="Overlay" style={appliedImageStyles} />
            <div className="card-img-overlay">
                <h5 className="card-title" style={appliedTitleStyles}>{title}</h5>
                <p className="card-text" style={appliedBodyTextStyles}>{bodyText}</p>
                <a href="#" className="btn btn-primary" style={appliedButtonStyles}>Go somewhere</a>
            </div>
        </div>
    );
};

export default ImageOverlayCard;
