function pageBuilderReducer(state, action) {
    switch (action.type) {
        case 'ADD_ROW':
            return { ...state, rows: [...state.rows, { id: `row_${new Date().getTime()}`, sections: [] }] };
        case 'ADD_COLUMN_IN_COLUMN': {
            const { rowId: targetRowId, sectionId: targetSectionId, columnIdx, newColumns } = action.payload;
            
            return {
                ...state,
                rows: state.rows.map(row => {
                    if (row.id === targetRowId) {
                        return {
                            ...row,
                            sections: row.sections.map(section => {
                                if (section.id === targetSectionId) {
                                    return {
                                        ...section,
                                        columns: section.columns.map((col, idx) => {
                                            if (idx === columnIdx) {
                                                const updatedSections = [
                                                    {
                                                        id: `section_${Date.now()}`,  // Create a new section
                                                        columns: newColumns.map(colClass => ({
                                                            id: `column_${Date.now()}`,  // Create new columns
                                                            class: colClass,
                                                            components: [],
                                                        }))
                                                    }
                                                ];
        
                                                return {
                                                    ...col,
                                                    sections: updatedSections
                                                };
                                            }
                                            return col;
                                        })
                                    };
                                }
                                return section;
                            })
                        };
                    }
                    return row;
                })
            };
        }
            
        case 'UPDATE_COLUMNS_IN_SECTION':
            const { rowId: targetRowId, sectionId: targetSectionId, columns: newColumns } = action.payload;
    
            return {
                ...state,
                rows: state.rows.map(row =>
                    row.id === targetRowId ? {
                        ...row,
                        sections: row.sections.map(section =>
                            section.id === targetSectionId ? {
                                ...section,
                                columns: [
                                    ...section.columns,  // Preserve existing columns
                                    ...newColumns.map(colClass => ({ 
                                        id: `column_${Date.now()}`,  // Generate a unique ID
                                        class: colClass, 
                                        components: [] // column with no component
                                    }))
                                ]
                            } : section
                        )
                    } : row
                )
            };
        
        case 'CHANGE_LAYOUT': {
            const { rowId: rowID, sectionId: sectionId, columns: updatedColumn ,subSection:section} = action.payload;
        
            return {
                ...state,
                rows: state.rows.map(row =>
                    row.id === rowID
                        ? {
                            ...row,
                            sections: row.sections.map(section => {
                                if (section.id === sectionId) {
                                    // Merge components from extra columns if reducing the number of columns
                                    const existingColumns = section.columns;
        
                                    // Check if the number of columns is being reduced
                                    if (existingColumns.length > updatedColumn.length) {
                                        const excessColumns = existingColumns.slice(updatedColumn.length); // Get columns to be merged
                                        const targetColumnIndex = updatedColumn.length - 1; // Last column of the new layout
                                        
                                        // Merge components from excess columns into the last column of the new layout
                                        const mergedComponents = excessColumns.reduce((components, col) => {
                                            return components.concat(col.components || []);
                                        }, existingColumns[targetColumnIndex]?.components || []);
                                    
                                        // Merge subsections from excess columns into the last column
                                        const mergedSubSections = excessColumns.reduce((subSections, col) => {
                                            return subSections.concat(col.sections || []);
                                        }, existingColumns[targetColumnIndex]?.sections || []);
                                    
                                        return {
                                            ...section,
                                            columns: updatedColumn.map((colClass, index) => {
                                                const existingCol = section.columns[index] || {};
                                                
                                                return {
                                                    class: colClass, // Update column class
                                                    components: index === targetColumnIndex 
                                                        ? mergedComponents // Merge components into the last column
                                                        : existingCol.components || [], //existing components
                                                    
                                                    sections: index === targetColumnIndex
                                                        ? mergedSubSections // Merge subsections into the last column
                                                        : existingCol.sections || [] // existing subsections
                                                };
                                            })
                                        };
                                    }
                                    
        
                                    // If not reducing columns
                                    return {
                                        ...section,
                                        columns: updatedColumn.map((colClass, index) => {
                                            const existingCol = section.columns[index] || {};
                                            
                                            return {
                                                class: colClass, // Update column class from the new layout
                                                components: existingCol.components || [], // Retain existing components if any
                                                
                                                // Check if the existing column has subsections
                                                sections: existingCol.sections
                                                    ? existingCol.sections.map(subSection => ({
                                                          ...subSection,
                                                          columns: subSection.columns.map((subCol, subIndex) => ({
                                                              ...subCol, // Preserve the subsection columns structure
                                                              components: subCol.components || [] // Retain components in subsection columns
                                                          }))
                                                      }))
                                                    : [] // If no subsections, return an empty array
                                            };
                                        })
                                    };
                                    
                                }
                                return section;
                            })
                        }
                        : row
                )
            };
        }

        case 'CHANGE_SUB_SECTION_LAYOUT': {
            const { rowId: rowID, sectionId: sectionId, columns: updatedSubColumns, subSection:subSectionId } = action.payload;

            return {
                ...state,
                rows: state.rows.map(row =>
                    row.id === rowID
                        ? {
                            ...row,
                            sections: row.sections.map(section => {
                                if (section.id === sectionId) {
                                    return {
                                        ...section,
                                        columns: section.columns.map(col => {
                                            if (col.sections) {
                                                return {
                                                    ...col,
                                                    sections: col.sections.map(subSection => {
                                                        if (subSection.id === subSectionId) {
                                                            
                                                            const existingSubColumns = subSection.columns;
        
                                                            if (existingSubColumns.length > updatedSubColumns.length) {
                                                                const excessSubColumns = existingSubColumns.slice(updatedSubColumns.length);
                                                                const targetSubColumnIndex = updatedSubColumns.length - 1;
        
                                                                const mergedSubComponents = excessSubColumns.reduce((components, subCol) => {
                                                                    return components.concat(subCol.components || []);
                                                                }, existingSubColumns[targetSubColumnIndex]?.components || []);
        
                                                                return {
                                                                    ...subSection,
                                                                    columns: updatedSubColumns.map((subColClass, index) => {
                                                                        const existingSubCol = subSection.columns[index] || {};
                                                                        return {
                                                                            class: subColClass,
                                                                            components: index === targetSubColumnIndex
                                                                                ? mergedSubComponents
                                                                                : existingSubCol.components || []
                                                                        };
                                                                    })
                                                                };
                                                            }
        
                                                            return {
                                                                ...subSection,
                                                                columns: updatedSubColumns.map((subColClass, index) => {
                                                                    const existingSubCol = subSection.columns[index] || {};
                                                                    return {
                                                                        class: subColClass,
                                                                        components: existingSubCol.components || []
                                                                    };
                                                                })
                                                            };
                                                        }
                                                        return subSection;
                                                    })
                                                };
                                            }
                                            return col;
                                        })
                                    };
                                }
                                return section;
                            })
                        }
                        : row
                )
            };
        }
              
        case 'REMOVE_COLUMN_FROM_SECTION':
            const { rowId: removeColumnRowId, sectionId: removeColumnSectionId, columnIndex } = action.payload;
            return {
                ...state,
                rows: state.rows.map(row =>
                    row.id === removeColumnRowId ? {
                        ...row,
                        sections: row.sections.map(section =>
                            section.id === removeColumnSectionId ? {
                                ...section,
                                columns: section.columns.filter((_, idx) => idx !== columnIndex)
                            } : section
                        )
                    } : row
                )
            };

        case 'UPDATE_SECTION_IN_ROW':
            const { rowId: updateRowId, columns } = action.payload;
            return {
                ...state,
                rows: state.rows.map(row =>
                    row.id === updateRowId ? { ...row, sections: [...row.sections, { id: `section_${new Date().getTime()}`, columns: columns.map(colClass => ({ class: colClass, components: [] })) }] } : row
                )
            };

        case 'ADD_COMPONENT_TO_COLUMN': {
            const { sectionId: addSectionId, columnIdx, component } = action.payload;
            return {
                ...state,
                rows: state.rows.map(row => ({
                    ...row,
                    sections: row.sections.map(section =>
                        section.id === addSectionId
                            ? {
                                ...section,
                                columns: section.columns.map((col, idx) =>
                                    idx === columnIdx
                                        ? { ...col, components: [...col.components, component] }
                                        : col
                                ),
                            }
                            : section
                    ),
                })),
            };
        }
        
        case 'ADD_COMPONENT_TO_SUB_COLUMN': {
            const { rowId: currentRow, sectionId: targetsectionId, columnIdx: parentColId, component: Targetcomponent, subSectionId, subColIdx,sectionColId} = action.payload;

                return {
                    ...state,
                    rows: state.rows.map(row => {
                        if (row.id === currentRow) {
                            return {
                                ...row,
                                sections: row.sections.map(section => {
                                    if (section.id == targetsectionId) {
                                        return {
                                            ...section,
                                            columns: section.columns.map((col, idx) => {
                                                if (idx === parentColId) {
                                                    return {
                                                        ...col,
                                                        sections: col.sections && subSectionId
                                                            ? col.sections.map(nestedSection => {
                                                                if (nestedSection.id === subSectionId) {
                                                                    return {
                                                                        ...nestedSection,
                                                                        columns: nestedSection.columns.map((nestedCol, subIdx) => {
                                                                            let nestedSectionColId = `${nestedSection.id}_${subIdx}`;
                                                                            
                                                                            if (nestedSectionColId === sectionColId && subIdx === subColIdx) {
                                                                                return {
                                                                                    ...nestedCol,
                                                                                    components: [...nestedCol.components, Targetcomponent]
                                                                                };
                                                                            }
                                                                            if (nestedCol.components.length === 0 && nestedSectionColId === sectionColId) {
                                                                                return {
                                                                                    ...nestedCol,
                                                                                    components: [Targetcomponent]
                                                                                };
                                                                            }
                
                                                                            return nestedCol;
                                                                        })
                                                                    };
                                                                } else {
                                                                    return nestedSection;
                                                                }
                                                            })
                                                            : col.sections
                                                    };
                                                }
                                                return col;
                                            })
                                        };
                                    }
                                    return section;
                                })
                            };
                        }
                        return row;
                    })
                };
        }
        
        case 'UPDATE_COMPONENT_IN_COLUMN': {
            const { sectionId: updateSectionId,subSection, columnIdx: updateColumnIdx, componentIdx, updatedComponent } = action.payload;
          
            console.log('j');
            console.log(updatedComponent);

            return {
                ...state,
                rows: state.rows.map(row => {
                    return {
                        ...row,
                        sections: row.sections.map(section => {
                            
                            // Handle top-level component
                            if (section.id === updateSectionId) {
                                return {
                                    ...section,
                                    columns: section.columns.map((col, colIdx) => {
                                        if (colIdx === updateColumnIdx) {
                                            return {
                                                ...col,
                                                components: col.components.map((comp, compIdx) =>
                                                    compIdx === componentIdx ? updatedComponent : comp
                                                )
                                            };
                                        } else {
                                            return col;
                                        }
                                    })
                                };
                            }

                            else if ( typeof updateSectionId == 'string'||section.id != updateSectionId) {
                                return {
                                    ...section,
                                    columns: section.columns.map((col) => {
                                        return {
                                            ...col,
                                            sections: col.sections
                                            ? col.sections.map(nestedSection => ({
                                                ...nestedSection,
                                                columns: nestedSection.columns.map((nestedCol, colIdx) => {
                                                    if (nestedCol.components && nestedCol.components.length > 0 && colIdx === updateColumnIdx) {
                                                        return {
                                                            ...nestedCol,
                                                            components: nestedCol.components.map((nestedComp, nestedCompIdx) =>
                                                                nestedCompIdx === componentIdx ? updatedComponent : nestedComp
                                                            )
                                                        };
                                                    }
                                                    return nestedCol;
                                                })
                                            }))
                                            : []
                                        };
                                    })
                                };
                            } else {
                                return section;
                            }
                        })
                    };
                })
            };
        }
        
        case 'REMOVE_COMPONENT_FROM_COLUMN': {
            const { rowId, sectionId: removeComponentSectionId, columnIdx: removeComponentColumnIdx, componentIdx: removeComponentIdx, isMainToSub,isMainToMain,tempFromSectionId ,isSecton,columnIndex} = action.payload;
            
            return {
                ...state,
                rows: state.rows.map(row => {
                    return {
                        ...row,
                        sections: row.sections.map(section => {
                            if(!isMainToMain && !isSecton){
                                if (section.id === removeComponentSectionId && tempFromSectionId !== null && tempFromSectionId !== section.id ) {
                                    
                                    return {
                                        ...section,
                                        columns: section.columns.map((col, colIdx) => {
                                            if (colIdx === removeComponentColumnIdx) {
                                                return {
                                                    ...col,
                                                    components: col.components.filter((_, compIdx) => compIdx !== removeComponentIdx),
                                                };
                                            }
                                            return col;
                                        })
                                    };
                                }
                            }else if (isSecton){
                                if (section.id !== removeComponentSectionId && tempFromSectionId == section.id || tempFromSectionId == null) {
                                    
                                    return {
                                        ...section,
                                        columns: section.columns.map((col, colIdx) => {
                                            if (colIdx === columnIndex ) {
                                                return {
                                                    ...col,
                                                    components: col.components.filter((_, compIdx) => compIdx !== removeComponentIdx),
                                                };
                                            }
                                            return col;
                                        })
                                    };
                                }
                            }else{
                                if (section.id !== removeComponentSectionId && isMainToMain && tempFromSectionId == null) {
                                    
                                    return {
                                        ...section,
                                        columns: section.columns.map((col, colIdx) => {
                                            if (colIdx == removeComponentColumnIdx || colIdx == removeComponentColumnIdx  ) {
                                                return {
                                                    ...col,
                                                    components: col.components.filter((_, compIdx) => compIdx !== removeComponentIdx),
                                                };
                                            }
                                            return col;
                                        })
                                    };
                            }
                        }
                            // Handle nested section logic if needed (if isMainToSub is relevant)
                            return section;
                        })
                    };
                })
            };
        }
        
        case 'REMOVE_SUB_COMPONENT_FROM_COLUMN': {
            const {
                rowId,
                sectionId: removeComponentSectionId,
                subSectionId: subSection,
                columnIdx: removeComponentColumnIdx,
                componentIdx: removeComponentIdx,
                isMainToSub,
                fromsectionId
            } = action.payload;
        
            return {
                ...state,
                rows: state.rows.map(row => {
                    return {
                        ...row,
                        sections: row.sections.map(section => {
                            // Check for main column removal if `isMainToSub` is true
                            if (section.id === removeComponentSectionId && isMainToSub) {
                                return {
                                    ...section,
                                    columns: section.columns.map((col, colIdx) => {
                                        if (colIdx === removeComponentColumnIdx) {
                                            return {
                                                ...col,
                                                components: col.components.filter(
                                                    (_, compIdx) => compIdx !== removeComponentIdx
                                                ),
                                            };
                                        }
                                        return col;
                                    }),
                                };
                            }
        
                            // Check for nested section removal
                            if (section.id === removeComponentSectionId || section.id !== removeComponentSectionId) {
                                return {
                                    ...section,
                                    columns: section.columns.map(col => ({
                                        ...col,
                                        sections: col.sections
                                            ? col.sections.map(nestedSection => {
                                                if (fromsectionId === '') {
                                                    // If `fromsectionId` is empty, match on `subSection`
                                                    if (nestedSection.id === subSection) {
                                                        return {
                                                            ...nestedSection,
                                                            columns: nestedSection.columns.map((nestedCol, colIdx) => {
                                                                if (colIdx === removeComponentColumnIdx) {
                                                                    return {
                                                                        ...nestedCol,
                                                                        components: nestedCol.components.filter(
                                                                            (_, nestedCompIdx) => nestedCompIdx !== removeComponentIdx
                                                                        ),
                                                                    };
                                                                }
                                                                return nestedCol;
                                                            }),
                                                        };
                                                    }
                                                } else if (nestedSection.id === fromsectionId) {
                                                    // If `fromsectionId` is defined, match directly on it
                                                    return {
                                                        ...nestedSection,
                                                        columns: nestedSection.columns.map((nestedCol, colIdx) => {
                                                            if (colIdx === removeComponentColumnIdx) {
                                                                return {
                                                                    ...nestedCol,
                                                                    components: nestedCol.components.filter(
                                                                        (_, nestedCompIdx) => nestedCompIdx !== removeComponentIdx
                                                                    ),
                                                                };
                                                            }
                                                            return nestedCol;
                                                        }),
                                                    };
                                                }
                                                return nestedSection; // Return as is if no match
                                            })
                                            : [],
                                    })),
                                };
                            }
        
                            return section; // Return section as is if no match
                        }),
                    };
                }),
            };
        }
        
            
        case 'REMOVE_ROW':
            return { ...state, rows: state.rows.filter(row => row.id !== action.payload) };
        case 'REMOVE_SECTION': {
            const { rowId: removeRowId, sectionId: removeSectionId, subSectionId: removedSubSection } = action.payload;
        
            return {
                ...state,
                rows: state.rows.map(row => {
                    // Check if the rowId matches the current row
                    if (row.id === removeRowId) {
                        return {
                            ...row,
                            sections: row.sections
                                // Remove the section from the main row if sectionId matches
                                .filter(section => section.id !== removeSectionId)
                                .map(section => ({
                                    ...section,
                                    columns: section.columns.map(column => ({
                                        ...column,
                                        // Remove the subsection if subSectionId matches
                                        sections: (column.sections || []).filter(subSection => subSection.id !== removedSubSection),
                                    })),
                                })),
                        };
                    } 
                    // Return unmodified rows if rowId doesn't match
                    return row;
                }),
            };
        }
            

        case 'REMOVE_SUB_SECTION': {
            const { rowId: removeRowId, sectionId: removeSectionId, subSectionId: removedSubSection } = action.payload;
        
            return {
                ...state,
                rows: state.rows.map(row => {
                    if (row.id === removeRowId) { // Ensure you're in the correct row
                        return {
                            ...row,
                            sections: row.sections.map(section => {
                                if (section.id === removeSectionId) { // Ensure you're in the correct section
                                    return {
                                        ...section,
                                        columns: section.columns.map(column => ({
                                            ...column,
                                            // Only remove the subsection from the nested sections
                                            sections: (column.sections || [])
                                                .filter(subSection => subSection.id !== removedSubSection) // Remove subsection
                                        }))
                                    };
                                }
                                return section; // Return unmodified section if sectionId doesn't match
                            })
                        };
                    }
                    return row; // Return unmodified row if rowId doesn't match
                })
            };
        }
        
        case 'MOVE_ROW':
            const { fromRowIndex, toRowIndex } = action.payload;
            const movedRows = [...state.rows];
            const [removedRow] = movedRows.splice(fromRowIndex, 1);
            movedRows.splice(toRowIndex, 0, removedRow);
            return { ...state, rows: movedRows };
        case 'MOVE_SECTION':
            const { fromSectionIndex, toSectionIndex, rowId: moveSectionRowId } = action.payload;
            const updatedRows = state.rows.map(row => {
                if (row.id === moveSectionRowId) {
                    const updatedSections = [...row.sections];
                    const [removedSection] = updatedSections.splice(fromSectionIndex, 1);
                    updatedSections.splice(toSectionIndex, 0, removedSection);
                    return { ...row, sections: updatedSections };
                }
                return row;
            });
            return { ...state, rows: updatedRows };
        //move subsection        //move subsection

        case 'MOVE_SUB_SECTION': {
            const { rowId: moveSubSectionRowId, id:subSectionId,columnIdx, fromSubSectionIndex, toSubSectionIndex } = action.payload;
            const updatedRows = state.rows.map(row => {
                    return {
                        ...row,
                        sections: row.sections.map(section => {
                                return {
                                    ...section,
                                    columns: section.columns.map((col, colIdx) => {
                                        if(colIdx === columnIdx){
                                            const updatedSubSections = [...col.sections];
                                            const [removedSubSection] = updatedSubSections.splice(fromSubSectionIndex, 1);
                                            updatedSubSections.splice(toSubSectionIndex, 0, removedSubSection);
                                            return { ...col, sections: updatedSubSections };
                                        }
                                        return col;
                                    })
                                };
                            return section;
                        })
                    };
                return row;
            });

            return { ...state, rows: updatedRows };
        }

        case 'MOVE_COMPONENT':
            const { fromSectionId, toSectionId, fromColumnIdx, toColumnIdx, fromComponentIdx, toComponentIdx } = action.payload;
            const updatedState = state.rows.map(row => ({
                ...row,
                sections: row.sections.map(section => {
                    if (section.id === fromSectionId || section.id === toSectionId) {
                        const fromComponent = section.columns.flatMap((col, idx) =>
                            idx === fromColumnIdx ? col.components.filter((_, idx) => idx === fromComponentIdx) : []
                        );
                        if (section.id === fromSectionId) {
                            section.columns.forEach((col, idx) => {
                                if (idx === fromColumnIdx) {
                                    col.components.splice(fromComponentIdx, 1);
                                }
                            });
                        }
                        if (section.id === toSectionId) {
                            section.columns.forEach((col, idx) => {
                                if (idx === toColumnIdx) {
                                    col.components.splice(toComponentIdx, 0, ...fromComponent);
                                }
                            });
                        }
                    }
                    return section;
                })
            }));
            return { ...state, rows: updatedState };
        case 'CLONE_ROW':
            const { rowId: cloneRowId } = action.payload;
            const clonedRow = state.rows.find(row => row.id === cloneRowId);
            const newRow = { ...clonedRow, id: `row_${new Date().getTime()}` };
            return { ...state, rows: [...state.rows, newRow] };

        case 'DISABLE_ROW': {
            const { rowId } = action.payload;
        
            return {
                ...state,
                rows: state.rows.map(row => {
                    if (row.id === rowId) {
                        
                        const newDisabledState = !row.disabled; // Toggle disabled state
        
                        return {
                            ...row,
                            disabled: newDisabledState, // Toggle the row's disabled state
                            sections: row.sections.map(section => ({
                                ...section,
                                disabled: newDisabledState, // Set same state for section
                                columns: section.columns.map(col => ({
                                    ...col,
                                    disabled: newDisabledState, // Set same state for column
                                    components: col.components.map(component => ({
                                        ...component,
                                        disabled: newDisabledState // Set same state for component
                                    })),
                                    sections: col.sections?.map(nestedSection => ({
                                        ...nestedSection,
                                        disabled: newDisabledState, // Set same state for nested section
                                        columns: nestedSection.columns.map(nestedCol => ({
                                            ...nestedCol,
                                            disabled: newDisabledState, // Set same state for nested column
                                            components: nestedCol.components.map(nestedComponent => ({
                                                ...nestedComponent,
                                                disabled: newDisabledState // Set same state for nested component
                                            }))
                                        }))
                                    })) || []
                                }))
                            }))
                        };
                    }
                    return row;
                })
            };
        }

        case 'DISABLE_SECTION': {
            const { rowId, sectionId } = action.payload;
        
            return {
                ...state,
                rows: state.rows.map(row => {
                    if (row.id === rowId) {
        
                        return {
                            ...row,
                            sections: row.sections.map(section => {
                                if (section.id === sectionId) {
                                    const newDisabledState = !section.disabled;
        
                                    return {
                                        ...section,
                                        disabled: newDisabledState, // Disable section
                                        columns: section.columns.map(col => ({
                                            ...col,
                                            disabled: newDisabledState, // Disable column
                                            components: col.components.map(component => ({
                                                ...component,
                                                disabled: newDisabledState // Disable component
                                            })),
                                            sections: col.sections?.map(nestedSection => ({
                                                ...nestedSection,
                                                disabled: newDisabledState, // Disable nested section
                                                columns: nestedSection.columns.map(nestedCol => ({
                                                    ...nestedCol,
                                                    disabled: newDisabledState, // Disable nested column
                                                    components: nestedCol.components.map(nestedComponent => ({
                                                        ...nestedComponent,
                                                        disabled: newDisabledState // Disable nested component
                                                    }))
                                                }))
                                            })) || []
                                        }))
                                    };
                                }
                                return section;
                            })
                        };
                    }
                    return row;
                })
            };
        }

        case 'DISABLE_SUB_SECTION': {
            const { rowId, sectionId, subSectionId } = action.payload;
        
            return {
                ...state,
                rows: state.rows.map(row => {
                    if (row.id === rowId) {
        
                        return {
                            ...row,
                            sections: row.sections.map(section => {
                                if (section.id === sectionId) {
                                    return {
                                        ...section,
                                        columns: section.columns.map(col => {
                                            // Iterate over each column to find the nested subsection
                                            return {
                                                ...col,
                                                sections: col.sections?.map(nestedSection => {
                                                    if (nestedSection.id === subSectionId) {
                                                        const newDisabledState = !nestedSection.disabled; 
        
                                                        return {
                                                            ...nestedSection,
                                                            disabled: newDisabledState, // Disable nested section
                                                            columns: nestedSection.columns.map(nestedCol => ({
                                                                ...nestedCol,
                                                                disabled: newDisabledState, // Disable nested column
                                                                components: nestedCol.components.map(nestedComponent => ({
                                                                    ...nestedComponent,
                                                                    disabled: newDisabledState // Disable nested component
                                                                }))
                                                            }))
                                                        };
                                                    }
                                                    return nestedSection;
                                                }) || []
                                            };
                                        })
                                    };
                                }
                                return section;
                            })
                        };
                    }
                    return row;
                })
            };
        }
        
        


        case 'CLONE_SECTION':
            const { rowId: cloneSectionRowId, sectionId: cloneSectionId } = action.payload;
            const clonedSectionRow = state.rows.find(row => row.id === cloneSectionRowId);
            const clonedSection = clonedSectionRow.sections.find(section => section.id === cloneSectionId);
            const newSection = { ...clonedSection, id: `section_${new Date().getTime()}` };
            return {
                ...state,
                rows: state.rows.map(row =>
                    row.id === cloneSectionRowId ? { ...row, sections: [...row.sections, newSection] } : row
                )
            };

        case 'CLONE_NESTED_SECTION':
            const { rowId: SectionRowId, sectionId: SectionId, subSectionId, colIdx } = action.payload;
            const cloneSectionRow = state.rows.find(row => row.id === SectionRowId);
            const cloneSection = cloneSectionRow.sections.find(section => section.id === SectionId);
            const cloneColumn = cloneSection.columns[colIdx];
            const cloneSubSection = cloneColumn.sections.find(subSection => subSection.id === subSectionId);
            const newSubSection = { ...cloneSubSection, id: `cloneSubSection_${new Date().getTime()}` };
        
            // Update the state with the cloned sub-section
            return {
                ...state,
                rows: state.rows.map(row => {
                    if (row.id === SectionRowId) {
        
                        return {
                            ...row,
                            sections: row.sections.map(section => {
                                if (section.id === SectionId) {
        
                                    return {
                                        ...section,
                                        columns: section.columns.map((column, index) => {
                                            if (index === colIdx) {
        
                                                return {
                                                    ...column,
                                                    sections: [...column.sections, newSubSection] // Add the new sub-section
                                                };
                                            } else {
                                                return column;
                                            }
                                        })
                                    };
                                } else {
                                    return section;
                                }
                            })
                        };
                    } else {
                        return row;
                    }
                })
            };
            
        case 'ADD_SECTION_AT_POSITION':
            const { rowId: addSectionRowId, columns: addSectionColumns, position } = action.payload;
            return {
                ...state,
                rows: state.rows.map(row =>
                    row.id === addSectionRowId ? {
                        ...row,
                        sections: [
                            ...row.sections.slice(0, position),
                            { id: `section_${new Date().getTime()}`, columns: addSectionColumns.map(colClass => ({ class: colClass, components: [] })) },
                            ...row.sections.slice(position)
                        ]
                    } : row
                )
            };
        case 'MOVE_ROW_TO_ANOTHER_SECTION':
            const { fromRowId, toRowId, rowIndex } = action.payload;
            let movedRow;
            const updatedRows2 = state.rows.map(row => {
                if (row.id === fromRowId) {
                    movedRow = row.sections.splice(rowIndex, 1)[0];
                }
                if (row.id === toRowId) {
                    row.sections.splice(rowIndex, 0, movedRow);
                }
                return row;
            });
            return { ...state, rows: updatedRows2 };
        case 'LOAD_PAGE_DATA':

            return {
                ...state,
                rows: action.payload.page_builder_rows.map(row => {
                    return {
                        id: row.id,
                        disabled: row.disabled, // Include the disabled state for the row
                        sections: row.page_builder_sections.map(section => {
                            return {
                                id: section.id,
                                disabled: section.disabled, // Include the disabled state for the section
                                columns: section.page_builder_columns.map(column => {
                                    return {
                                        class: column.class,
                                        disabled: column.disabled, // Include the disabled state for the column
                                        components: column.page_builder_components.map(component => {
                                            return {
                                                type: component.type,
                                                props: JSON.parse(component.props),
                                                disabled: component.disabled // Include the disabled state for the component
                                            };
                                        }),
                                        sections: column.page_builder_subsections?.map(subsection => {
                                            return {
                                                id: subsection.id,
                                                disabled: subsection.disabled, // Include the disabled state for the subsection
                                                columns: subsection.page_builder_subcolumns.map(subcolumn => {
                                                    return {
                                                        class: subcolumn.class,
                                                        disabled: subcolumn.disabled, // Include the disabled state for the subcolumn
                                                        components: subcolumn.page_builder_subcomponents.map(subcomponent => {
                                                            return {
                                                                type: subcomponent.type,
                                                                props: JSON.parse(subcomponent.props),
                                                                disabled: subcomponent.disabled // Include the disabled state for the subcomponent
                                                            };
                                                        })
                                                    };
                                                })
                                            };
                                        }) || []
                                    };
                                })
                            };
                        })
                    };
                })
            };

        default:
            return state;
    }
}

export default pageBuilderReducer;