import React from 'react';
import { Form } from 'react-bootstrap';

const ButtonForm = ({ formValues, setFormValues }) => (
    <Form.Group controlId="formButtonText">
        <Form.Label>Button Text</Form.Label>
        <Form.Control
            type="text"
            name="text"
            value={formValues.text || ''}
            onChange={(e) => setFormValues({ ...formValues, text: e.target.value })}
        />
    </Form.Group>
);

export default ButtonForm;
