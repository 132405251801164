import React from 'react';
import { Form } from 'react-bootstrap';

const TextForm = ({ formValues, setFormValues }) => {
    return (
        <Form.Group controlId="formTextContent">
            <Form.Label>Text Content</Form.Label>
            <Form.Control
                type="text"
                name="content"
                value={formValues.content || ''}
                onChange={(e) => setFormValues({ ...formValues, content: e.target.value })}
            />
        </Form.Group>
    );
};

export default TextForm;
