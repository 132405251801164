import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function ModalComponent({ title, body, buttonLabel }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                {buttonLabel || 'Open Modal'}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title || 'Default Modal Title'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body || 'Default Modal Body'}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalComponent;
