import React, { useState } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';

const ImageForm = ({ formValues, setFormValues }) => {
    const [showLibrary, setShowLibrary] = useState(false);

    const handleImageSelect = (url) => {
        setFormValues({ ...formValues, imgSrc: url });
        setShowLibrary(false); // Close media library after selection
    };

    return (
        <>
            <Form.Group controlId="formImageSrc">
                <Form.Label>Selected Image</Form.Label>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {formValues.imgSrc ? (
                        <Image 
                            src={formValues.imgSrc} 
                            alt="Selected Image" 
                            thumbnail 
                            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                        />
                    ) : (
                        <div style={{ width: '100px', height: '100px', backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            No Image Selected
                        </div>
                    )}
                </div>
            </Form.Group>

            <MediaLibrary 
                onSelectImage={handleImageSelect} 
                show={showLibrary} 
                setShow={setShowLibrary}
            />
        </>
    );
};

export default ImageForm;
