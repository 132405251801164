import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer bg-light desktop-view">
            <div className="container-fluid">
                <div className="row text-center text-md-start">
                    <div className="col-md-6 col-sm-12 mt-3 mb-3">
                        <ul className="list-unstyled foot-links">
                            <li><Link to="/privacy/policy" className="text-decoration-none">Privacy Policy</Link></li>
                            <li><Link to="/terms/use" className="text-decoration-none">Terms of Use</Link></li>
                            <li><Link to="/contact/us" className="text-decoration-none">Need Help?</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-sm-12 mt-3 text-md-end">
                        <div className="text-muted">Copyright &copy; 2023 FIKLU. All Rights Reserved.</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
