import React from 'react';
import { useAppData } from '../AppDataContext';

const CategoryList = () => {
    const { categories } = useAppData();

    return (
        <div className="mt-3">
            <div className="shop-by-category mobile-view">
                <h2><span>Shop by Category</span></h2>
                <div className="categories-slide">
                    <ul>
                        {categories.map((category, index) => (
                            <li key={index}>
                                <div className="product-img" style={{
                                    backgroundImage: `url(${category.media_url})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                                <h3>{category.name}</h3>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="shop-by-category desktop-view">
                <h2><span>Shop by Category</span></h2>
                <div className="categories-flex">
                    <ul>
                        {categories.map((category, index) => (
                            <li key={index}>
                                <div className="product-img" style={{
                                    backgroundImage: `url(${category.media_url})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                                <h3>{category.name}</h3>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CategoryList;
