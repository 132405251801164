import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const PricingTableComponent = ({ plans = [], styles = {} }) => (
    <div className="row price-wrap">
        {plans.map((plan, idx) => (
            <div key={idx} className="col-md-4">
                <div className="card mb-4">
                    <div 
                        className="card-header"
                        style={{
                            fontFamily: styles.planName?.fontFamily,
                            fontSize: styles.planName?.fontSize,
                            color: styles.planName?.color,
                            fontWeight: styles.planName?.fontWeight,
                            textAlign: styles.planName?.textAlign,
                            margin: styles.planName?.margin,
                            padding: styles.planName?.padding,
                            letterSpacing: styles.planName?.letterSpacing,
                            lineHeight: styles.planName?.lineHeight
                        }}
                    >
                        <h4 className="my-0 font-weight-normal">{plan.name}</h4>
                    </div>
                    <div className="card-body">
                        <div 
                            className="card-title pricing-card-title"
                            style={{
                                fontFamily: styles.price?.fontFamily,
                                fontSize: styles.price?.fontSize,
                                color: styles.price?.color,
                                fontWeight: styles.price?.fontWeight,
                                textAlign: styles.price?.textAlign,
                                letterSpacing: styles.price?.letterSpacing,
                                lineHeight: styles.price?.lineHeight,
                                margin: styles.price?.margin,
                                padding: styles.price?.padding
                            }}
                        >
                            ${plan.price} <small className="text-muted">- per month</small>
                        </div>
                        <ul 
                            className="list-unstyled mt-3 mb-4"
                            style={{
                                fontFamily: styles.features?.fontFamily,
                                fontSize: styles.features?.fontSize,
                                color: styles.features?.color,
                                fontWeight: styles.features?.fontWeight,
                                lineHeight: styles.features?.lineHeight,
                                letterSpacing: styles.features?.letterSpacing,
                                textAlign: styles.features?.textAlign,
                                margin: styles.features?.margin,
                                padding: styles.features?.padding
                            }}
                        >
                            {plan.features.map((feature, featureIdx) => (
                                <li key={featureIdx}><FontAwesomeIcon icon={faCheck} /> {feature}</li>
                            ))}
                        </ul>
                        <button 
                            type="button" 
                            className="btn btn-primary"
                            style={{
                                backgroundColor: styles.button?.backgroundColor,
                                color: styles.button?.color,
                                fontFamily: styles.button?.fontFamily,
                                fontSize: styles.button?.fontSize,
                                fontWeight: styles.button?.fontWeight,
                                padding: styles.button?.padding,
                                borderRadius: styles.button?.borderRadius,
                                letterSpacing: styles.button?.letterSpacing,
                                lineHeight: styles.button?.lineHeight,
                                border: styles.button?.border
                            }}
                            onClick={() => window.open(styles.button?.link, "_blank")}
                        >
                            Sign up
                        </button>
                    </div>
                </div>
            </div>
        ))}
    </div>
);

export default PricingTableComponent;
