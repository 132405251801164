import React, { useState } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';

const CarouselForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const [showLibrary, setShowLibrary] = useState({}); // Track which item is opening the MediaLibrary

    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="carousel-wrap">
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Group>
                                {item.src ? (
                                    <Image 
                                        src={item.src} 
                                        alt={item.alt} 
                                        thumbnail 
                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                    />
                                ) : (
                                    <div className="carousel-no-img">
                                        No image selected
                                    </div>
                                )}


                                <MediaLibrary
                                    onSelectImage={(url) => {
                                        const updatedItems = [...formValues[fieldName]];
                                        updatedItems[index].src = url; // Set the selected image URL to 'src' of the item
                                        setFormValues({ ...formValues, [fieldName]: updatedItems });
                                        setShowLibrary({ [index]: false }); // Close the media library for the item
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-9">
                            {Object.keys(itemSchema).map((key) => key !== 'src' && (
                                <Form.Control
                                    key={key}
                                    type="text"
                                    placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                                    value={item[key]}
                                    onChange={(e) => {
                                        const updatedItems = [...formValues[fieldName]];
                                        updatedItems[index][key] = e.target.value;
                                        setFormValues({ ...formValues, [fieldName]: updatedItems });
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                    

                    

                    <Button variant="danger" className="mt-2" onClick={() => handleRemoveJsonArrayItem(fieldName, index)}>
                        Remove
                    </Button>

                </div>
            ))}
            <Button variant="primary" onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)}>
                Add More
            </Button>
        </>
    );

    return (
        <>
            {renderJsonArrayFields('items', { src: '', alt: '', caption: '' })}
            <Form.Group controlId="formCarouselFullscreen" className="mt-3">
                <Form.Check
                    type="checkbox"
                    label="Display as Full-Screen at the Top"
                    name="isFullScreen"
                    checked={formValues.isFullScreen || false}
                    onChange={(e) =>
                        setFormValues({
                            ...formValues,
                            isFullScreen: e.target.checked
                        })
                    }
                />
            </Form.Group>
        </>
    );
};

export default CarouselForm;
