import React from 'react';

function BlockquoteComponent({ quote, author }) {
    return (
        <blockquote className="blockquote">
            <p className="mb-0">{quote || "Default quote text goes here."}</p>
            <footer className="blockquote-footer">{author || "Unknown Author"}</footer>
        </blockquote>
    );
}

export default BlockquoteComponent;
