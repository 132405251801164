import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    
    const [cartItems, setCartItems] = useState(() => {
        // Load cart items from local storage
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cartItems));
        console.log("Cart saved to localStorage.");
    }, [cartItems]);


    const addToCart = (product, quantity = 1, selectedOptions = {}) => {
        setCartItems(prevItems => {
            const existingItemIndex = prevItems.findIndex(item => 
                item.product.product_id === product.product_id && 
                JSON.stringify(item.selectedOptions) === JSON.stringify(selectedOptions)
            );
            if (existingItemIndex > -1) {
                // Update the quantity of an existing item
                const newItems = [...prevItems];
                newItems[existingItemIndex].quantity += quantity;
                return newItems;
            } else {
                // Add new item to the cart
                return [...prevItems, { product, quantity, selectedOptions }];
            }
        });
    };

    const removeFromCart = (productId, selectedOptions = {}) => {
        setCartItems(prevItems => {
            return prevItems.filter(item => 
                item.product.product_id !== productId || 
                JSON.stringify(item.selectedOptions) !== JSON.stringify(selectedOptions)
            );
        });
    };

    const updateQuantity = (productId, newQuantity, selectedOptions) => {
        console.log(`Updating product ${productId} to quantity ${newQuantity}`);
        setCartItems(currentItems => {
            const newItems = currentItems.map(item => {
                if (item.product.product_id === productId) {
                    console.log(`Found item. Old quantity: ${item.quantity}, New quantity: ${newQuantity}`);
                    return { ...item, quantity: newQuantity };
                }
                return item;
            }).filter(item => item.quantity > 0);
            console.log("New items array:", newItems);
            return newItems;
        });
    };

    const emptyCart = () => {
        setCartItems([]);
        console.log("Cart emptied.");
    };

    return (
        <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, updateQuantity, emptyCart }}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
