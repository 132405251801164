import React from 'react';

const BootstrapCardComponent = ({ title, imageUrl, bodyText, styles = {} }) => {
  const { title: titleStyles = {}, image: imageStyles = {}, bodyText: bodyTextStyles = {} } = styles;

  const appliedTitleStyles = {
    fontFamily: titleStyles.fontFamily,
    fontSize: titleStyles.fontSize,
    color: titleStyles.color,
    fontWeight: titleStyles.fontWeight,
    textAlign: titleStyles.textAlign,
    marginTop: titleStyles.marginTop,
    marginRight: titleStyles.marginRight,
    marginBottom: titleStyles.marginBottom,
    marginLeft: titleStyles.marginLeft,
    paddingTop: titleStyles.paddingTop,
    paddingRight: titleStyles.paddingRight,
    paddingBottom: titleStyles.paddingBottom,
    paddingLeft: titleStyles.paddingLeft,
    letterSpacing: titleStyles.letterSpacing,
    lineHeight: titleStyles.lineHeight,
  };

  const appliedImageStyles = {
    width: imageStyles.width,
    height: imageStyles.height,
    borderRadius: imageStyles.borderRadius,
    boxShadow: imageStyles.boxShadow,
    borderColor: imageStyles.borderColor,
    borderWidth: imageStyles.borderWidth,
    borderStyle: 'solid',
    marginTop: imageStyles.marginTop,
    marginRight: imageStyles.marginRight,
    marginBottom: imageStyles.marginBottom,
    marginLeft: imageStyles.marginLeft,
    paddingTop: imageStyles.paddingTop,
    paddingRight: imageStyles.paddingRight,
    paddingBottom: imageStyles.paddingBottom,
    paddingLeft: imageStyles.paddingLeft,
    display: imageStyles.alignment === 'center' ? 'block' : 'inline-block',
    margin: imageStyles.alignment === 'center' ? '0 auto' : undefined,
  };

  const appliedBodyTextStyles = {
    fontFamily: bodyTextStyles.fontFamily,
    fontSize: bodyTextStyles.fontSize,
    color: bodyTextStyles.color,
    fontWeight: bodyTextStyles.fontWeight,
    textAlign: bodyTextStyles.textAlign,
    lineHeight: bodyTextStyles.lineHeight,
    letterSpacing: bodyTextStyles.letterSpacing,
    marginTop: bodyTextStyles.marginTop,
    marginRight: bodyTextStyles.marginRight,
    marginBottom: bodyTextStyles.marginBottom,
    marginLeft: bodyTextStyles.marginLeft,
    paddingTop: bodyTextStyles.paddingTop,
    paddingRight: bodyTextStyles.paddingRight,
    paddingBottom: bodyTextStyles.paddingBottom,
    paddingLeft: bodyTextStyles.paddingLeft,
  };

  return (
    <div className="card card-wrap">
      <img src={imageUrl} alt="Dynamic" style={appliedImageStyles} />
      <div className="card-body">
        <h5 className="card-title" style={appliedTitleStyles}>{title}</h5>
        <p className="card-text" style={appliedBodyTextStyles}>{bodyText}</p>
      </div>
    </div>
  );

};

export default BootstrapCardComponent;
