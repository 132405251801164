import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const FAQComponent = ({ faqs = [], styles = {}, title = "FAQ" }) => {
    const [openIndexes, setOpenIndexes] = useState([]);

    const handleToggle = (index) => {
        setOpenIndexes((prev) => 
            prev.includes(index) 
                ? prev.filter((i) => i !== index) 
                : [...prev, index]
        );
    };

    return (
        <div className="accordion" id="faqAccordion">
            <h2 style={styles.title || {}}>{title}</h2>
            {faqs.map((faq, idx) => (
                <div key={idx} className="card">
                    <div 
                        className="card-header" 
                        id={`heading${idx}`}
                        style={styles.question || {}}
                    >
                        <h2>
                            <button 
                                className="btn btn-link" 
                                type="button" 
                                onClick={() => handleToggle(idx)} 
                                aria-expanded={openIndexes.includes(idx)} 
                                aria-controls={`collapse${idx}`}
                                style={styles.question || {}}
                            >
                                <FontAwesomeIcon icon={faCheck} /> {faq.question}
                            </button>
                        </h2>
                    </div>
                    <div 
                        id={`collapse${idx}`} 
                        className={`collapse ${openIndexes.includes(idx) ? 'show' : ''}`} 
                        aria-labelledby={`heading${idx}`} 
                        data-bs-parent="#faqAccordion"
                    >
                        <div 
                            className="card-body faqAnswer" 
                            style={styles.answer || {}}
                        >
                            {faq.answer}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FAQComponent;
