import React, { useMemo, useState } from 'react';
import { Container, Row, Col, Button, Form, Card, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faMinus, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useCart } from './component/CartContext'; // Adjust the import path as necessary
import Footer from "./component/Footer";
import { loadStripe } from '@stripe/stripe-js';

import { API_URL } from '../config';

const stripePromise = loadStripe('pk_test_51N1rXoBBF79XvB8GY7prfq24ekAcJmMB339TtHEss4OOTx4NJVNlDIdSiJuMtmdbzrUDjVqQKOJMHGxixFv0Uvuy004aDR0ETY'); // Replace with your actual publishable key

const CartPage = () => {
    const { cartItems, updateQuantity, removeFromCart } = useCart();
  
    const [loading, setLoading] = useState(false);

    const handleIncrement = (product, selectedOptions) => {
        const newQuantity = product.quantity + 1;
        updateQuantity(product.product.product_id, newQuantity, selectedOptions);
    };

    const handleDecrement = (product, selectedOptions) => {
        const newQuantity = Math.max(1, product.quantity - 1);
        updateQuantity(product.product.product_id, newQuantity, selectedOptions);
    };

    const handleRemoveItem = (product, selectedOptions) => {
        removeFromCart(product.product_id, selectedOptions);
    };

    const handlePayment = async () => {
        setLoading(true);
        const stripe = await stripePromise;
        const response = await fetch(`${API_URL}/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cartItems }),
        });
        const session = await response.json();
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        if (result.error) {
            console.log(result.error.message);
        }
        setLoading(false);
    };

    const totals = useMemo(() => {
        let subtotal = cartItems.reduce((total, item) => total + (item.quantity * parseFloat(item.product.basePrice)), 0);
        let discount = 10; // $10 discount for demonstration
        let delivery = 5; // $5 delivery charge
        return {
            subtotal: subtotal.toFixed(2),
            discount: discount.toFixed(2),
            delivery: delivery.toFixed(2),
            total: (subtotal - discount + delivery).toFixed(2)
        };
    }, [cartItems]);

    return (
        <div className="margin-top cart-page-wrap">
            <Container fluid className="">
                <div className="breadcrumbs desktop-view"><div className="container-fluid"><ul><li><a href="/home">Home</a></li><li>Products</li></ul></div></div>
                <Row>
                    <Col md={8} className="cart-left-wrap mb-4">
                        <div className="address-wrap"><span>Deliver to:</span> SCO 22-23, Office No 312, 3rd floor, K10 Tower, Feroze Gandhi Market, Ludhiana</div>
                        <Card>
                            <Card.Body className="p-0">
                                {cartItems.map((item, index) => (
                                    <div className="cart-items">
                                        <div className="product-img">
                                            <img src="https://www.fiklu.com/media/4" />
                                        </div>
                                        <div className="cart-product-detail">
                                            <div className="product-name">{item.product.product_name}</div>
                                            <div className="product-price">Price: ${item.product.basePrice}</div>
                                            <div className="quantity-controls">
                                                <div className="quantity-inner">
                                                    <Button variant="outline-secondary" onClick={() => handleDecrement(item, item.selectedOptions)}>
                                                        -
                                                    </Button>

                                                    <Form.Control
                                                        type="text"
                                                        readOnly
                                                        value={item.quantity}
                                                    />
                                                    <Button variant="outline-secondary" onClick={() => handleIncrement(item, item.selectedOptions)}>
                                                        +
                                                    </Button>    
                                                </div>
                                                <Button variant="" className="remove-btn" onClick={() => handleRemoveItem(item.product, item.selectedOptions)} style={{ marginLeft: 'auto' }}>
                                                    <FontAwesomeIcon icon={faTrash} /> Remove from cart
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                        <div className="proceed-action text-end">
                            <Button variant="success" onClick={handlePayment} disabled={loading}>
                                {loading ? 'Processing...' : 'Proceed to Pay'}  <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                        </div>
                        
                    </Col>
                    <Col md={4}>
                        <div className="cart-summary-wrap">
                            <h4>Order Summary</h4>
                            <ListGroup variant="flush">
                                <ListGroup.Item>Subtotal <span>${totals.subtotal}</span></ListGroup.Item>
                                <ListGroup.Item>Discount  <span>-${totals.discount}</span></ListGroup.Item>
                                <ListGroup.Item>Delivery  <span>${totals.delivery}</span></ListGroup.Item>
                                <ListGroup.Item className="total-wrap"><strong>Total  <span>${totals.total}</span></strong></ListGroup.Item>
                            </ListGroup>
                        </div>                        
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CartPage;
