import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { API_URL } from '../../config';
import { useAppData } from './AppDataContext';

const MediaLibrary = ({ onSelectImage }) => {
    const [mediaItems, setMediaItems] = useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('media'); // Default tab is Media Library

    // Get `account_key` (subdomain) from AppData context
    const { subdomain } = useAppData();

    // Fetch media items associated with account_key
    const fetchMediaItems = async () => {
        try {
            const response = await fetch(`${API_URL}/media?account_key=${subdomain}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setMediaItems(data.media);
        } catch (error) {
            console.error('Failed to fetch media:', error);
        }
    };

    // Upload media file using Dropzone with account_key
    const handleFileUpload = async (acceptedFiles) => {
        if (acceptedFiles.length === 0) return;

        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);

        setLoading(true);

        try {
            const response = await fetch(`${API_URL}/media/upload?account_key=${subdomain}`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();

            if (data.success && data.media) {
                setMediaItems((prevItems) => [data.media, ...prevItems]); // Add new media to the media list
                setActiveTab('media'); // Switch to the media library tab on successful upload
            }
        } catch (error) {
            console.error('Failed to upload file:', error);
        } finally {
            setLoading(false);
        }
    };

    // Dropzone setup for image uploading
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleFileUpload,
        accept: 'image/*',
        multiple: false
    });

    // Fetch media items on component mount
    useEffect(() => {
        fetchMediaItems();
    }, [subdomain]);

    // Open Media Library when clicking 'Select Media'
    const openMediaLibrary = () => {
        setShow(true);
        setActiveTab('media'); // Set default tab to Media Library
    };

    return (
        <>
            <div onClick={openMediaLibrary} style={{ display: 'inline-block', cursor: 'pointer' }}>
                Change Media
            </div>

            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Media Library</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                        <Tab eventKey="media" title="Media Library">
                            <div className="media-library mt-3 d-flex flex-wrap">
                                {mediaItems.map((media) => (
                                    <img
                                        key={media.id}
                                        src={media.url}
                                        alt={media.title}
                                        onClick={() => {
                                            onSelectImage(media.url);
                                            setShow(false); // Close the modal upon selecting an image
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            margin: '10px',
                                            maxWidth: '100px',
                                            maxHeight: '100px',
                                            border: '1px solid #ddd',
                                            borderRadius: '5px'
                                        }}
                                    />
                                ))}
                                {mediaItems.length === 0 && <p>No media available.</p>}
                            </div>
                        </Tab>
                        <Tab eventKey="upload" title="Upload Media">
                            <div className="mt-3" {...getRootProps()} style={{
                                border: '2px dashed #007bff',
                                borderRadius: '5px',
                                padding: '20px',
                                textAlign: 'center',
                                cursor: 'pointer'
                            }}>
                                <input {...getInputProps()} />
                                {loading ? 'Uploading...' : 'Drag & drop an image here, or click to select one'}
                            </div>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MediaLibrary;
