import React from 'react';
import { Form } from 'react-bootstrap';

const HeadingForm = ({ formValues, setFormValues }) => (
    <>
        <Form.Group controlId="formHeadingContent">
            <Form.Label>Heading Content</Form.Label>
            <Form.Control
                type="text"
                name="text"
                value={formValues.text || ''}
                onChange={(e) => setFormValues({ ...formValues, text: e.target.value })}
            />
        </Form.Group>
        <Form.Group controlId="formHeadingLevel">
            <Form.Label>Heading Level</Form.Label>
            <Form.Select
                name="level"
                value={formValues.level || '1'} // Default to "1" if formValues.level is not set
                onChange={(e) => setFormValues({ ...formValues, level: e.target.value })}
            >
                <option value="1">h1</option>
                <option value="2">h2</option>
                <option value="3">h3</option>
                <option value="4">h4</option>
                <option value="5">h5</option>
                <option value="6">h6</option>
                <option value="7">h7</option>
                <option value="8">h8</option>

            </Form.Select>
        </Form.Group>
    </>
);

export default HeadingForm;
