import React from 'react';

const TimelineComponent = ({ events }) => (

    <ul className="timeline">
        {events.map((event, idx) => (
            <li key={idx}>
                <div className="timeline-panel">
                    <div className="date-wrap">Apr 5 <span>2024</span></div>
                    <div className="timeline-heading">
                        <h4 className="timeline-title">{event.title}</h4>
                    </div>
                    <div className="timeline-body">
                        <p>{event.description}</p>
                    </div>
                </div>
            </li>
        ))}
    </ul>
);

export default TimelineComponent;
