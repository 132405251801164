import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const HorizontalCard = ({ title, imageUrl, bodyText, button, styles = {} }) => {
    // Apply styles from the `styles` prop
    const titleStyles = {
        fontFamily: styles.title?.fontFamily,
        fontSize: styles.title?.fontSize,
        color: styles.title?.color,
        fontWeight: styles.title?.fontWeight,
        textAlign: styles.title?.textAlign,
        margin: styles.title?.margin,
        padding: styles.title?.padding,
        letterSpacing: styles.title?.letterSpacing,
        lineHeight: styles.title?.lineHeight,
    };

    const bodyTextStyles = {
        fontFamily: styles.bodyText?.fontFamily,
        fontSize: styles.bodyText?.fontSize,
        color: styles.bodyText?.color,
        fontWeight: styles.bodyText?.fontWeight,
        textAlign: styles.bodyText?.textAlign,
        lineHeight: styles.bodyText?.lineHeight,
        letterSpacing: styles.bodyText?.letterSpacing,
        margin: styles.bodyText?.margin,
        padding: styles.bodyText?.padding,
    };

    const imageStyles = {
        width: styles.image?.width,
        height: styles.image?.height,
        borderRadius: styles.image?.borderRadius,
        boxShadow: styles.image?.boxShadow,
        borderColor: styles.image?.borderColor,
        borderWidth: styles.image?.borderWidth,
        borderStyle: styles.image?.borderColor ? 'solid' : 'none',
        margin: styles.image?.margin,
        padding: styles.image?.padding,
    };

    const buttonStyles = {
        backgroundColor: styles.button?.backgroundColor,
        color: styles.button?.color,
        fontFamily: styles.button?.fontFamily,
        fontSize: styles.button?.fontSize,
        fontWeight: styles.button?.fontWeight,
        padding: styles.button?.padding,
        borderRadius: styles.button?.borderRadius,
        letterSpacing: styles.button?.letterSpacing,
        lineHeight: styles.button?.lineHeight,
        border: styles.button?.border,
        textDecoration: 'none',
        display: 'inline-block',
    };

    return (
        <div className="card mb-3 h-card-wrap">
            <div className="row g-0">
                <div className="col-md-4">
                    <img src={imageUrl} className="img-fluid rounded-start" alt="Horizontal" style={imageStyles} />
                </div>
                <div className="col-md-8 h-card-col">
                    <div className="card-body">
                        <h5 className="card-title" style={titleStyles}>{title}</h5>
                        <p className="card-text" style={bodyTextStyles}>{bodyText}</p>
                        <a href="#" className="btn btn-primary" style={buttonStyles}>Go somewhere</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HorizontalCard;
