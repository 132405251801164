import React from 'react';

function AboutUsComponent() {
    return (
        <div className="container-fluid"> {/* Changed to container-fluid for full width */}
            <div className="jumbotron bg-light text-center">
                <h1>About Us</h1>
                <p>Learn more about our team and mission</p>
            </div>

            {/* About the Company Section */}
            <div className="container py-5"> {/* Standard container for padding */}
                <h2>Our Mission</h2>
                <p>
                    At [Your Company], we aim to provide the best services and solutions to our clients.
                    We are dedicated to excellence and committed to innovation. Join us as we continue to grow and evolve.
                </p>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <h3>What We Do</h3>
                        <p>We offer a wide range of services and products that help businesses achieve their goals. Our solutions are tailored to meet the unique needs of each client.</p>
                    </div>
                    <div className="col-md-6">
                        <h3>Our Vision</h3>
                        <p>To be the leading provider of innovative solutions in our industry, recognized for our passion and commitment to excellence.</p>
                    </div>
                </div>
            </div>

            {/* Team Section */}
            <div className="container py-5">
                <h2>Meet Our Team</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card">
                            <img className="card-img-top" src="https://via.placeholder.com/300x300" alt="Team member"/>
                            <div className="card-body">
                                <h5 className="card-title">Jane Doe</h5>
                                <p className="card-text">CEO</p>
                                <p className="card-text">With over 20 years in the industry, Jane leads our team with a deep passion for our mission.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card">
                            <img className="card-img-top" src="https://via.placeholder.com/300x300" alt="Team member"/>
                            <div className="card-body">
                                <h5 className="card-title">John Smith</h5>
                                <p className="card-text">CTO</p>
                                <p className="card-text">John is a pioneer in our technology department, steering the development of our cutting-edge solutions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card">
                            <img className="card-img-top" src="https://via.placeholder.com/300x300" alt="Team member"/>
                            <div className="card-body">
                                <h5 className="card-title">Sarah Lee</h5>
                                <p className="card-text">CFO</p>
                                <p className="card-text">Sarah manages our financial strategy and ensures our growth is both sustainable and scalable.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsComponent;
