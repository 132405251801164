import React, { useState, useEffect, lazy, Suspense } from 'react';
import Loading from './component/Loading';  // Import the LoadingOverlay component
import { API_URL } from '../config';
import { useAppData } from './component/AppDataContext';

const CustomPages = lazy(() => import('./CustomPages'));

const Home = () => {
  const [customHomePage, setCustomHomePage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { subdomain } = useAppData();

  useEffect(() => {
    const fetchHomePage = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_URL}/pages/home/${subdomain}`);
        const data = await response.json();
        if (data && data.is_home_page) {
          setCustomHomePage(data.id);  // Only save page ID
        }
      } catch (error) {
        console.error("Error fetching custom home page:", error);
      } finally {
        setIsLoading(false);  // End loading
      }
    };

    fetchHomePage();
  }, [subdomain]);

  if (isLoading) {
    return <Loading />;  // Show LoadingOverlay while fetching data
  }

  if (customHomePage) {
    return (
      <Suspense fallback={<Loading />}>  {/* Show LoadingOverlay while lazy-loading CustomPages */}
        <CustomPages pageId={customHomePage} />
      </Suspense>
    );
  }

  return (
    <div>
      <h1>Welcome to the Default Home Page</h1>
      {/* Other default home page content */}
    </div>
  );
};

export default Home;
