import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

function TabsComponent({ tabs }) {
    return (
        <Tabs defaultActiveKey={tabs && tabs[0] && tabs[0].key} id="uncontrolled-tab-example">
            {tabs && tabs.length > 0
                ? tabs.map((tab, index) => (
                    <Tab eventKey={tab.key} title={tab.title} key={index}>
                        {tab.content}
                    </Tab>
                ))
                : <Tab eventKey="default" title="Default Tab">
                    Default Tab Content
                </Tab>}
        </Tabs>
    );
}

export default TabsComponent;
