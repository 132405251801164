import React from 'react';
import { Form, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faStar, faHeart, faBell, faCoffee, faHome, faUser, faEnvelope, faCamera, 
    faCalendar, faCartPlus, faCheck, faTimes, faArrowUp, faArrowDown, 
    faArrowLeft, faArrowRight, faCar, faCloud, faBolt, faUmbrella, 
    faBook, faBuilding, faPaperPlane, faSmile, faMusic, faSearch, faThumbsUp, 
    faThumbsDown, faEdit, faTrash, faPlus, faMinus, faLightbulb, faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const availableIcons = [
    { name: 'faStar', icon: faStar },
    { name: 'faHeart', icon: faHeart },
    { name: 'faBell', icon: faBell },
    { name: 'faCoffee', icon: faCoffee },
    { name: 'faHome', icon: faHome },
    { name: 'faUser', icon: faUser },
    { name: 'faEnvelope', icon: faEnvelope },
    { name: 'faCamera', icon: faCamera },
    { name: 'faCalendar', icon: faCalendar },
    { name: 'faCartPlus', icon: faCartPlus },
    { name: 'faCheck', icon: faCheck },
    { name: 'faTimes', icon: faTimes },
    { name: 'faArrowUp', icon: faArrowUp },
    { name: 'faArrowDown', icon: faArrowDown },
    { name: 'faArrowLeft', icon: faArrowLeft },
    { name: 'faArrowRight', icon: faArrowRight },
    { name: 'faCar', icon: faCar },
    { name: 'faCloud', icon: faCloud },
    { name: 'faBolt', icon: faBolt },
    { name: 'faUmbrella', icon: faUmbrella },
    { name: 'faBook', icon: faBook },
    { name: 'faBuilding', icon: faBuilding },
    { name: 'faPaperPlane', icon: faPaperPlane },
    { name: 'faSmile', icon: faSmile },
    { name: 'faMusic', icon: faMusic },
    { name: 'faSearch', icon: faSearch },
    { name: 'faThumbsUp', icon: faThumbsUp },
    { name: 'faThumbsDown', icon: faThumbsDown },
    { name: 'faEdit', icon: faEdit },
    { name: 'faTrash', icon: faTrash },
    { name: 'faPlus', icon: faPlus },
    { name: 'faMinus', icon: faMinus },
    { name: 'faLightbulb', icon: faLightbulb },
    { name: 'faMapMarkerAlt', icon: faMapMarkerAlt },
    // Brand icons
    { name: 'faFacebook', icon: faFacebook },
    { name: 'faTwitter', icon: faTwitter },
    { name: 'faInstagram', icon: faInstagram },
    { name: 'faLinkedin', icon: faLinkedin }
];

const IconCardForm = ({ formValues, setFormValues }) => {
    const handleIconChange = (icon) => {
        setFormValues({ ...formValues, icon });
    };

    return (
        <>
            <Form.Group controlId="formIcon">
                <Form.Label>Select Icon</Form.Label>
                <DropdownButton
                    title={formValues.icon ? <FontAwesomeIcon icon={formValues.icon} /> : 'Choose Icon'}
                    onSelect={(iconName) => {
                        const selectedIcon = availableIcons.find(icon => icon.name === iconName)?.icon;
                        handleIconChange(selectedIcon);
                    }}
                >
                    {availableIcons.map(({ name, icon }) => (
                        <Dropdown.Item key={name} eventKey={name}>
                            <FontAwesomeIcon icon={icon} className="me-2" />
                            {name}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </Form.Group>
            <Form.Group controlId="formCardTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    name="title"
                    value={formValues.title || ''}
                    onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                />
            </Form.Group>
            <Form.Group controlId="formCardDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    type="text"
                    name="description"
                    value={formValues.description || ''}
                    onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
                />
            </Form.Group>
        </>
    );
};

export default IconCardForm;
