import React from 'react';
//import 'lightbox2/dist/css/lightbox.min.css';
//import lightbox from 'lightbox2';

const LightboxGalleryComponent = ({ images }) => (
    <div className="lightbox-gallery">
        {images.map((image, idx) => (
            <a key={idx} href={image.src} data-lightbox="gallery" data-title={image.caption}>
                <img src={image.thumbnail} alt={image.caption} className="img-thumbnail" />
            </a>
        ))}
    </div>
);

export default LightboxGalleryComponent;
