import React from 'react';

function ButtonComponent({ text, styles = {} }) {
    // Extract button styles from styles object
    const buttonStyles = styles?.buttonStyles || {};

    // Define applied styles for the button
    const appliedStyles = {
        backgroundColor: buttonStyles.backgroundColor,
        borderRadius: buttonStyles.borderRadius,
        border: buttonStyles.border ? `${buttonStyles.border}px solid ${buttonStyles.borderColor || '#000'}` : 'none',
        fontFamily: buttonStyles.fontFamily,
        fontSize: buttonStyles.fontSize,
        color: buttonStyles.color || '#fff', // Default to white text if no color is specified
        letterSpacing: buttonStyles.letterSpacing,
        lineHeight: buttonStyles.lineHeight,
        marginTop: buttonStyles.marginTop,
        marginRight: buttonStyles.marginRight,
        marginBottom: buttonStyles.marginBottom,
        marginLeft: buttonStyles.marginLeft,
        paddingTop: buttonStyles.paddingTop,
        paddingRight: buttonStyles.paddingRight,
        paddingBottom: buttonStyles.paddingBottom,
        paddingLeft: buttonStyles.paddingLeft,
        textDecoration: buttonStyles.textDecoration,
        opacity: buttonStyles.opacity,
        cursor: 'pointer',
    };

    // Handle the button link
    const handleClick = () => {
        if (buttonStyles.link) {
            window.open(buttonStyles.link, '_blank');
        }
    };

    return (
        <button 
            style={appliedStyles} 
            onClick={handleClick}
            className="btn"
        >
            {text || "Click Me"}
        </button>
    );
}

export default ButtonComponent;
