import React from 'react';
import { Form } from 'react-bootstrap';

const ListForm = ({ formValues, setFormValues }) => (
    <Form.Group controlId="formListItems">
        <Form.Label>List Items</Form.Label>
        <Form.Control
            as="textarea"
            name="items"
            value={JSON.stringify(formValues.items, null, 2) || ''}
            onChange={(e) => setFormValues({ ...formValues, items: JSON.parse(e.target.value) })}
            rows={5}
        />
        <Form.Text className="text-muted">
            Enter the items as a JSON array.
        </Form.Text>
    </Form.Group>
);

export default ListForm;
