// components/droppable/DroppableSection.js
import React, { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';

const DroppableSection = ({ id, rowId, index, moveSection, onRemoveSection, onCloneSection, children }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'section',
        hover(item) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            moveSection(rowId, dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    });

    const [, drag] = useDrag({
        type: 'section',
        item: { type: 'section', id, rowId, index },
    });

    drag(drop(ref));

    return (
        <div ref={ref} className="droppable-section">
            {children}
        </div>
    );
};

export default DroppableSection;
