import React from 'react';

function AlertComponent({ type, message }) {
    return (
        <div className={`alert alert-${type || 'primary'}`} role="alert">
            {message || 'Default Alert Message'}
        </div>
    );
}

export default AlertComponent;
