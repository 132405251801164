import React, { useState, useRef } from 'react';
import { useAppData } from './AppDataContext';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useMediaQuery, useTheme } from '@mui/material';
import { useCart } from './CartContext'; // Ensure this path is correct
import useOutsideClick from '../../hooks/useOutsideClick'; // Adjust the import path based on your project structure
import { Navbar, Nav, NavDropdown, Button, Form, FormControl, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSearch, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Toolbar, IconButton, Typography, Badge, Drawer, List, ListItemButton, ListItemText, Collapse } from '@mui/material';;


const Header = ({ toggleBackdrop }) => {
    const ref = useRef();
    const { cartItems } = useCart(); // Using the cart context
    
    useOutsideClick(ref, () => {
        toggleBackdrop(false); // Hide backdrop through prop function
        setOpenDropdownId(null); // Close dropdown
    });

    const [openDropdownId, setOpenDropdownId] = useState(null);
 
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [openSubMenu, setOpenSubMenu] = useState(true);
    const [openSubMenus, setOpenSubMenus] = useState({});
    const [searchValue, setSearchValue] = useState('');

    const { logoUrl, menus } = useAppData();
   
    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const toggleDropdown = (dropdownId) => {
        if (openDropdownId === dropdownId) {
            setOpenDropdownId(null); // Close dropdown
            toggleBackdrop(false); // Hide backdrop through prop function
        } else {
            setOpenDropdownId(dropdownId); // Open dropdown
            toggleBackdrop(true); // Show backdrop through prop function
        }
    };

    const renderSubCategories = (subCategories, prefix = 1) => {
        return subCategories.map(subCategory => (
            <li key={subCategory.id} className={`level-${prefix}`}>
                <NavDropdown.Item href={subCategory.link_type === 'category' ? `/products?cat=${subCategory.id}` : '/products'}>
                    {prefix === 2 && <FontAwesomeIcon icon={faChevronRight} />}
                    {subCategory.name}
                </NavDropdown.Item>
                {subCategory.subCategories && subCategory.subCategories.length > 0 &&
                renderSubCategories(subCategory.subCategories, prefix + 1)}
            </li>
        ));
    };
    
    const renderMobileSubCategories = (subCategories, prefix = 1) => {
        return subCategories.map(subCategory => (
            <>
            <ListItemButton sx={{ pl: 4 }} component="a" href={subCategory.link_type === 'category' ? `/products?cat=${subCategory.id}` : '/products'}>
            <ListItemText sx={{ pl: 4 }} primary={subCategory.name} primaryTypographyProps={{ fontWeight: prefix === 1 ? 'bold' : '' }} />
            </ListItemButton>
            {subCategory.subCategories && subCategory.subCategories.length > 0 &&
                renderMobileSubCategories(subCategory.subCategories, prefix + 1)}
            </>
        ));
    };

    const renderMenuItems = (menuItems) => {
        return menuItems.map((menuItem) => {
            if (menuItem.subMenus && menuItem.subMenus.length > 0) {
                return (
                    <NavDropdown key={menuItem.id} title={menuItem.title} id={`dropdown-${menuItem.id}`} onClick={() => toggleDropdown(menuItem.id)}
                    >
                        {renderMenuItems(menuItem.subMenus)}
                    </NavDropdown>
                );
            } else if (menuItem.menu_type === 'mega_menu' && menuItem.link_type === 'category' && menuItem.category && menuItem.category.subCategories.length > 0) {
                return (
                    <NavDropdown
                        key={menuItem.id}
                        title={menuItem.title}
                        id={`mega-menu-${menuItem.id}`}
                        className="mega-menu-fullwidth"
                        show={openDropdownId === menuItem.id}
                        onClick={() => toggleDropdown(menuItem.id)} // Use onClick for simplicity
                    >
                        <ul>
                            {renderSubCategories(menuItem.category.subCategories)}
                        </ul>
                    </NavDropdown>
                );

            } else {
                // Generating URL based on link_type
                let href = menuItem.link || '#';
                if (menuItem.link_type === 'category') {
                    href = `/products?cat=${menuItem.category}`;
                } else if (menuItem.link_type === 'product' || menuItem.link_type === 'productListingPage') {
                    href = '/products';
                }

                return <Nav.Link key={menuItem.id} href={href}>{menuItem.title}</Nav.Link>;
            }
        });
    };

    const renderMobileMenuItems = (menuItems) => {
        return menuItems.map((menuItem) => {
            if (menuItem.subMenus && menuItem.subMenus.length > 0) {
                return (
                    <>
                        <ListItemButton sx={{ pl: 4 }} onClick={handleToggleSubmenu}>
                            <ListItemText primary={menuItem.title} />
                            {openSubSubMenu ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openSubSubMenu} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {renderMobileMenuItems(menuItem.subMenus) }
                            </List>
                        </Collapse>
                    </>
                );
            } else if (menuItem.menu_type === 'mega_menu' && menuItem.link_type === 'category' && menuItem.category && menuItem.category.subCategories.length > 0) {
                const isOpen = openSubMenus[menuItem.id] || false; 
                
                return (
                    <>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleToggleMainMenu(menuItem.id)}>
                            <ListItemText primary={menuItem.title} />
                            {isOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {renderMobileSubCategories(menuItem.category.subCategories)}
                            </List>
                        </Collapse>
                    </>
                );
            } else {
                let href = menuItem.link || '#';
                if (menuItem.link_type === 'category') {
                    href = `/products?cat=${menuItem.category}`;
                } else if (menuItem.link_type === 'product' || menuItem.link_type === 'productListingPage') {
                    href = '/products';
                }
                return (<ListItemButton sx={{ pl: 4 }} component="a" href={href}>
                    <ListItemText primary={menuItem.title} />
                </ListItemButton>);
            }
        });
    };
    const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);

    const [openSubSubMenu, setOpenSubSubMenu] = useState(true);

    const handleToggleMainMenu = (menuId) => {
        setOpenSubMenus((prevState) => ({
            ...prevState,
            [menuId]: !prevState[menuId],
        }));
    };

    const handleToggleSubmenu = () => {
        setOpenSubSubMenu(!openSubSubMenu);
    };

    const desktopMenu = (
        <div ref={ref}>
            <Navbar expand="lg" className="header-wrap fixed-top">
                <Container fluid>
                    <Navbar.Brand href="/" className="d-inline-block align-middle">
                        {logoUrl ? (
                            <img src={logoUrl} alt="Logo" width="120" height="auto" />
                        ) : (
                            <div></div> // Placeholder while the logo is being fetched
                        )}
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav testing">
                        <Nav className="me-auto">
                            {renderMenuItems(menus)}
                        </Nav>
                        {/* Icons Row */}
                        <Form className="d-flex search-flex">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                className="mr-2"
                                aria-label="Search"
                            />
                            <Button variant="outline-success" className="d-inline-flex align-items-center">
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </Form>
                        <Nav className="">
                            <Nav.Link href="/cart">
                                <IconButton color="inherit">
                                    <Badge badgeContent={2} color="error">
                                        <FavoriteIcon />
                                    </Badge>
                                </IconButton>
                            </Nav.Link>
                            <Nav.Link href="/cart">
                                <IconButton color="inherit">
                                    <Badge badgeContent={totalItems} color="error">
                                        <ShoppingCartIcon />
                                    </Badge>
                                </IconButton>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );


     const handleCloseDrawer = () => {
        setOpen(false);
    };

const mobileMenu = (
    <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ backgroundColor: 'white' }}>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2, color: 'black' }} // Set color to black
                    onClick={() => setOpen(true)}
                >
                    <MenuIcon />
                </IconButton>
                {logoUrl ? (
                    <img src={logoUrl} alt="Logo" style={{ width: 100, height: 'auto', marginRight: 'auto' }} />
                ) : (
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Loading...
                    </Typography>
                )}
                <Box sx={{ flexGrow: 1 }} />
                <Box className="search-top">
                    <Nav.Link href="/cart">
                        <Badge badgeContent={totalItems} color="error">
                            <ShoppingCartIcon />
                        </Badge>
                    </Nav.Link>
                    <Nav.Link href="/cart">
                        <Badge  color="error">
                            <FavoriteIcon />
                        </Badge>
                    </Nav.Link>
                    <Nav.Link href="/">
                        <FontAwesomeIcon icon={faSearch} />
                    </Nav.Link>
                </Box>
            </Toolbar>
        </AppBar>
        <Drawer
            anchor='left'
            open={open}
            onClose={handleCloseDrawer}
            sx={{ '& .MuiDrawer-paper': { backgroundColor: 'white' } }} // Set drawer background color to white
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
            >
                <IconButton
                    onClick={handleCloseDrawer}
                    sx={{ position: 'relative', top: 5, left: 200, margin: 0, color: 'black' }} // Set color to black
                >
                    <CloseIcon />
                </IconButton>
                <List>
                    {renderMobileMenuItems(menus)}
                </List>
            </Box>
        </Drawer>
        {/*<div className="search-wrap">
            <FontAwesomeIcon icon={faSearch} />
            <input 
                value={searchValue} 
                onChange={handleSearchChange} 
                placeholder="Search" 
                className="form-control" 
            />
        </div>*/}
    </Box>
);



    return isMobile ? mobileMenu : desktopMenu;
};

export default Header;
