import React from 'react';
import { Card } from 'react-bootstrap';

const TeamSectionComponent = ({ teamMembers }) => (

    <div className="product-inner-wrap">
        <div className="g-4 row">
            {teamMembers.map((member, idx) => (
                <div key={idx} className="col-md-4">
                    <Card className="">
                        <div className="image-wrap">
                            <Card.Img variant="top" src={member.image} alt={member.name} />
                        </div>
                        <Card.Body>
                            <Card.Title>{member.name}</Card.Title>
                            <Card.Text className="product-description">{member.position}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            ))}
        </div>
    </div>
);

export default TeamSectionComponent;
