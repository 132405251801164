import React from 'react';
import { Form } from 'react-bootstrap';

const HeaderFooterCardForm = ({ formValues, setFormValues }) => (
    <>
        <Form.Group controlId="formCardHeader">
            <Form.Label>Card Header</Form.Label>
            <Form.Control
                type="text"
                name="header"
                value={formValues.header || ''}
                onChange={(e) => setFormValues({ ...formValues, header: e.target.value })}
            />
        </Form.Group>
        <Form.Group controlId="formCardFooter">
            <Form.Label>Card Footer</Form.Label>
            <Form.Control
                type="text"
                name="footer"
                value={formValues.footer || ''}
                onChange={(e) => setFormValues({ ...formValues, footer: e.target.value })}
            />
        </Form.Group>
        <Form.Group controlId="formCardTitle">
            <Form.Label>Card Title</Form.Label>
            <Form.Control
                type="text"
                name="title"
                value={formValues.title || ''}
                onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
            />
        </Form.Group>
        <Form.Group controlId="formCardBodyText">
            <Form.Label>Body Text</Form.Label>
            <Form.Control
                type="text"
                name="bodyText"
                value={formValues.bodyText || ''}
                onChange={(e) => setFormValues({ ...formValues, bodyText: e.target.value })}
            />
        </Form.Group>
    </>
);

export default HeaderFooterCardForm;
