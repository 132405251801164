// components/droppable/DroppableSubSection.js
import React, { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';

const DroppableSubSection = ({ id, rowId, columnIdx, index, moveSubSection, children }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'subsection',
        hover(item) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            moveSubSection(rowId, id, columnIdx, dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    });

    const [, drag] = useDrag({
        type: 'subsection',
        item: { type: 'subsection', id, rowId, columnIdx, index },
    });

    drag(drop(ref));

    return (
        <div ref={ref} className="droppable-subsection">
            {children}
        </div>
    );
};

export default DroppableSubSection;
