import React from 'react';
import { Form } from 'react-bootstrap';

const AudioForm = ({ formValues, setFormValues }) => (
    <Form.Group controlId="formAudioSrc">
        <Form.Label>Audio Source</Form.Label>
        <Form.Control
            type="text"
            name="src"
            value={formValues.src || ''}
            onChange={(e) => setFormValues({ ...formValues, src: e.target.value })}
        />
    </Form.Group>
);

export default AudioForm;
