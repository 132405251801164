import React from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#0056b3', // Neutral, adaptable primary color
    },
    background: {
      default: '#fff', // Light, neutral background color
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: '2rem',
      color: '#333',
    },
  },
});

function SplashScreen() {
    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                sx={{
                  backgroundColor: theme.palette.background.default,
                }}
            >
                <img src="https://www.fiklu.com/media/11" alt="Fiklu Logo" style={{ width: '200px', marginBottom: '20px' }} />
                <Box width="80%" maxWidth="500px"> {/* Controls the width of the progress bar */}
                    <LinearProgress color="primary" />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default SplashScreen;
