import React from 'react';

function HeadingComponent({ level, text, styles }) {
    const HeadingTag = `h${level}`;
    const appliedStyles = styles?.text || {};  // Extract styles for the "text" section

    return <HeadingTag style={appliedStyles}>{text || `Heading ${level}`}</HeadingTag>;
}

export default HeadingComponent;
