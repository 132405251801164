import React from 'react';
import { Carousel } from 'react-bootstrap';

function CarouselComponent({ items }) {
    return (
        <Carousel className="slider-container" prevIcon={null} nextIcon={null}>
            {items && items.length > 0
                ? items.map((item, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={item.src}
                            alt={item.alt || `Slide ${index + 1}`}
                        />
                        <Carousel.Caption>
                            <h3>{item.caption || `Slide ${index + 1}`}</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))
                : <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="https://via.placeholder.com/800x400"
                        alt="Default Slide"
                    />
                    <Carousel.Caption>
                        <h3>Default Slide</h3>
                    </Carousel.Caption>
                </Carousel.Item>}
        </Carousel>
    );
}

export default CarouselComponent;
