import React from 'react';
import { useAppData } from '../AppDataContext';
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const ProductList = () => {
    const { products, productListingTitle } = useAppData();

    return (
        <div className="shop-by-category home-product">
            <h2><span>{productListingTitle}</span></h2>
            <div className="product-inner-wrap">
                <Row xs={2} md={3} lg={5} className="g-4">
                    {products.map(product => (
                        <Col key={product.product_id}>
                            <Card className="h-100">
                                <div className="image-wrap">
                                    <Card.Text className="rating">4.5 <FontAwesomeIcon icon={faStar} /></Card.Text>
                                    <Card.Img variant="top" src={product.media_urls} alt={product.product_name} />
                                </div>
                                <Card.Body>
                                    <Card.Title>{product.product_name}</Card.Title>
                                    <Card.Text className="product-description">{product.description}</Card.Text>
                                    <Card.Text className="price">
                                        <span className="product-discountedPrice">${product.discounted_price}</span>
                                        <span className="product-strike" style={{ textDecoration: 'line-through' }}>
                                            ${product.price}
                                        </span>
                                        <span className="product-discountPercentage">
                                            (Save {Math.round(((product.price - product.discounted_price) / product.price) * 100)}%)
                                        </span>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default ProductList;
