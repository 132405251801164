const loadGoogleMaps = (apiKey) => {
    return new Promise((resolve, reject) => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            resolve();
            return;
        }

        const existingScript = document.getElementById('googleMapsScript');
        if (existingScript) {
            existingScript.onload = () => resolve();
            existingScript.onerror = () => reject(new Error('Google Maps JavaScript API failed to load.'));
            return;
        }

        const script = document.createElement('script');
        script.id = 'googleMapsScript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
                resolve();
            } else {
                reject(new Error('Google Maps JavaScript API failed to load.'));
            }
        };
        script.onerror = () => {
            reject(new Error('Google Maps JavaScript API failed to load.'));
        };
        document.head.appendChild(script);
    });
};

export default loadGoogleMaps;
