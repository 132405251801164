import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function ListComponent({ items }) {
    return (
        <ul class="listing-wrap">
            {items && items.length > 0
                ? items.map((item, index) => <li key={index}><FontAwesomeIcon icon={faAngleRight} />  {item}</li>)
                : 'Default List Item'}
        </ul>
    );
}

export default ListComponent;
