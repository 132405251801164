import React from 'react';

function CheckboxComponent({ label, checked }) {
    return (
        <div className="form-check">
            <input type="checkbox" className="form-check-input" checked={checked} />
            <label className="form-check-label">{label}</label>
        </div>
    );
}

export default CheckboxComponent;
