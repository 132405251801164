import React from 'react';
import { Form } from 'react-bootstrap';

const IframeForm = ({ formValues, setFormValues }) => (
    <>
        <Form.Group controlId="formIframeSrc">
            <Form.Label>Iframe Source</Form.Label>
            <Form.Control
                type="text"
                name="src"
                value={formValues.src || ''}
                onChange={(e) => setFormValues({ ...formValues, src: e.target.value })}
            />
        </Form.Group>
        <Form.Group controlId="formIframeHeight">
            <Form.Label>Height</Form.Label>
            <Form.Control
                type="text"
                name="height"
                value={formValues.height || ''}
                onChange={(e) => setFormValues({ ...formValues, height: e.target.value })}
            />
        </Form.Group>
        <Form.Group controlId="formIframeWidth">
            <Form.Label>Width</Form.Label>
            <Form.Control
                type="text"
                name="width"
                value={formValues.width || ''}
                onChange={(e) => setFormValues({ ...formValues, width: e.target.value })}
            />
        </Form.Group>
    </>
);

export default IframeForm;
