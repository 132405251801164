import React, { useState, useEffect } from 'react';
import { useParams  } from 'react-router-dom';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"; // Import the CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping, faCalendarDays, faClock, faAngleDown, faArrowRight, faStar, faTrashCan } from '@fortawesome/free-solid-svg-icons'; // Import arrow right icon
import { API_URL } from '../config';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { FormCheck } from 'react-bootstrap';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import Loading from './component/Loading';
import { useCart } from './component/CartContext';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';


const ProductDetail = () => {
    const { productId } = useParams();
    const navigate = useNavigate();

    const { cartItems, addToCart, updateQuantity, removeFromCart } = useCart();
    const [quantity, setQuantity] = useState(1);
    const [isInCart, setIsInCart] = useState(false);

    useEffect(() => {
        const itemInCart = cartItems.find(item => item.product.product_id.toString() === productId);
        if (itemInCart && itemInCart.quantity>0) {
            setQuantity(itemInCart.quantity);
            setIsInCart(true);
        }
    }, [cartItems, productId, quantity]); 


    const [product, setProduct] = useState({
        product_name: '',
        description: '',
        basePrice: '',
        discountedPrice: '',
        media_ids: [],
        reviews: [],
        attributes: [],
        selectedOptions: {},
        customFields: [],
    });

    const handleAddToCart = () => {
        addToCart(product, quantity, product.selectedOptions);
        setIsInCart(true); // Update the state to reflect that the item is now in the cart
    };

    const goToCart = () => {
        navigate(`/cart`);
    };

    const incrementQuantity = () => {
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
        updateQuantity(product.product_id, newQuantity, product.selectedOptions);
    };

    const decrementQuantity = () => {
        const newQuantity = Math.max(1, quantity - 1);
        setQuantity(newQuantity);
        updateQuantity(product.product_id, newQuantity, product.selectedOptions);
    };

    const handleRemoveFromCart = () => {
        removeFromCart(product.product_id, product.selectedOptions);
        setIsInCart(false);  // Update UI state to reflect that the item is no longer in the cart
        setQuantity(1);  // Optionally reset the quantity to a default value after removal
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`${API_URL}/products/${productId}`);
                const data = await response.json();
                if (data.success && data.product) {
                    const images = data.product.media_urls.map(url => ({
                        original: url,
                        thumbnail: url // You might want to modify this if you have separate thumbnails
                    }));

          
                    setProduct({
                        ...data.product,
                        images,
                        customFields: data.product.custom_fields, 
                        basePrice: data.product.price,
                        discountedPrice: data.product.discounted_price,
                        attributes: data.product.attributes, // Assuming this structure from your backend
                    });

                    setLoading(false);
                }
            } catch (error) {
                console.error("Failed to fetch product details:", error);
                setLoading(false);

            }
    };

    fetchProductDetails();
    }, [productId]);

   

    return (
        <div className="margin-top product-detail-main">
            {loading ? <Loading text="" /> : ''}
            <Container fluid className="">
                <div className="breadcrumbs desktop-view"><div className="container-fluid"><ul><li><a href="/home">Home</a></li><li>Products</li></ul></div></div>
                <Row>
                    <Col md={5} className="desktop-view">
                        {/* Desktop ImageGallery */}
                        
                            {product.images && product.images.length > 0 && (
                                <ImageGallery items={product.images.map(img => ({
                                    original: img.original,
                                    thumbnail: img.thumbnail
                                }))} thumbnailPosition="left" />
                            )}
                        
                    </Col>
                    <Col md={12} className="mobile-view p-0 product-detail-wrap">
                    {/* Mobile Carousel */}
                        <div className="product-img-wrap">
                            <div className="rating">4.5 <FontAwesomeIcon icon={faStar} /></div>
                            {product.images && product.images.length > 0 ? (
                                <Carousel>
                                    {product.images.map((image, index) => (
                                        <Carousel.Item key={index}>
                                            <img className="d-block w-100" src={image.original} alt={`Slide ${index}`} />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            ) : (
                                <p>No images available</p>
                            )}
                        </div>
                    </Col>
                    <Col md={7} xs={12} className="product-disc-wrap">
                        <h2>{product.product_name}</h2>
                        <div className="image-wrap">
                            <Card.Text className="rating"><span>4.5 <FontAwesomeIcon icon={faStar} /></span> 12 Ratings</Card.Text>
                        </div>
                        
                        {product.discounted_price && parseFloat(product.discounted_price) < parseFloat(product.price) ? (
                            <>
                                <div className="price-detail">
                                    <span className="product-discountedPrice">${parseFloat(product.discounted_price).toFixed(2)}</span>
                                    <span className="product-strike" style={{ textDecoration: 'line-through' }}>
                                        ${parseFloat(product.price).toFixed(2)}
                                    </span>
                                    <span className="product-discountPercentage">
                                        (Save {((1 - parseFloat(product.discounted_price) / parseFloat(product.price)) * 100).toFixed(0)}%)
                                    </span>
                                </div>
                            </>
                        ) : (
                            <div className="price-detail">
                                <span className="product-discountedPrice">${parseFloat(product.price).toFixed(2)}</span>
                            </div>
                        )}

                        <hr/>

                        <div className="disc-detail">
                            <h4>Product Description</h4>
                            <p>{product.description}</p>
                        </div>

                        <hr/>
                        
{
    Object.entries(product.attributes || {}).map(([attributeId, attributeData]) => {
        switch (attributeData.attribute_type) {
            case 'text':
                return (
                    <Row key={attributeId} className="custom-form-wrap">
                        <Col xs={12} className="label-title">
                            <label>{attributeData.attribute_name}</label>
                        </Col>
                        <Col xs={12}>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={attributeData.attribute_name}
                            />
                        </Col>
                    </Row>
                );
            case 'textarea':
                return (
                    <Row key={attributeId} className="custom-form-wrap">
                        <Col xs={12} className="label-title">
                            <label>{attributeData.attribute_name}</label>
                        </Col>
                        <Col xs={12}>
                            <textarea
                                className="form-control"
                                placeholder={attributeData.attribute_name}
                            />
                        </Col>
                    </Row>
                );
            case 'selectdropdown':
                return (
                    <Row key={attributeId} className="custom-form-wrap">
                        <Col xs={12} className="label-title">
                            <label>{attributeData.attribute_name}</label>
                        </Col>
                        <Col xs={12}>
                            <select
                                className="form-control">                            
                                <option value="">Select</option>
                                {attributeData.options.map(option => (
                                    <option key={option.option_id} value={option.option_id}>
                                        {option.option_name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                );
            case 'date':
            return (
                <Row key={attributeId} className="custom-form-wrap z-in-3">
                    
                    <Col xs={12} className="date-box">
                        <FontAwesomeIcon icon={faCalendarDays} />
                        <ReactDatePicker className="form-control" value="Delivery Date"/>
                    </Col>
                </Row>

            );

            case 'datetime':
                return (
                    <Row key={attributeId} className="custom-form-wrap z-in-2">
                        <Col xs={12} className="date-box">
                            <FontAwesomeIcon icon={faClock} />
                            <ReactDatePicker
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className="form-control"
                                value="Delivery Date Time"
                            />
                        </Col>
                    </Row>
                );

            case 'boolean':
                return (
                    <Row key={attributeId} className="custom-form-wrap">
                        <Col xs={12} className="label-title">
                            <label>{attributeData.attribute_name}</label>
                        </Col>
                        <Col xs={12} className="icon-right">
                            <FontAwesomeIcon icon={faAngleDown} />
                            <select
                                className="form-control"
                            >
                                <option value="">Select</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </Col>
                    </Row>
                );
            case 'multiselectcheckboxes':
                return (
                    <Row key={attributeId} className="custom-form-wrap">
                        <Col xs={12} className="label-title">
                            <label>{attributeData.attribute_name}</label>
                        </Col>
                        <Col xs={12} className="check-area">
                            {attributeData.options.map(option => (
                                <FormCheck key={option.option_id}>
                                    <FormCheck.Input type="checkbox" id={`checkbox-${option.option_id}`} />
                                    <FormCheck.Label htmlFor={`checkbox-${option.option_id}`}>{option.option_name}</FormCheck.Label>
                                </FormCheck>
                            ))}
                        </Col>
                    </Row>
                );

            case 'multiselectdropdown':
                const options = attributeData.options.map(option => ({
                value: option.option_id.toString(), // Ensuring value is a string
                label: option.option_name
            }));


            return (
                <Row key={attributeId} className="custom-form-wrap">
                    <Col xs={12} className="label-title">
                        <label>{attributeData.attribute_name}</label>
                    </Col>
                    <Col xs={12}>
                        <Select
                            isMulti
                            name={`attribute_${attributeId}`}
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </Col>
                </Row>
            );
            case 'selectradiobutton':
                return (
                    <FormControl component="fieldset" key={attributeId} className="custom-form-wrap">
                        <FormLabel component="legend"  className="label-title">{attributeData.attribute_name}</FormLabel>
                        <RadioGroup aria-label={attributeData.attribute_name} name={`attribute_${attributeId}`}>
                            {attributeData.options.map(option => (
                                <FormControlLabel key={option.option_id} value={option.option_id.toString()} control={<Radio />} label={option.option_name} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                );

                        // Add cases for 'price' and 'media_image' as needed, depending on their specific handling requirements
                        default:
                            return null; // For any unhandled types, render nothing or a placeholder
                    }
                })
            }

                        <div className="quantity-controls">
                            <div className="quantity-inner">
                                <Button variant="outline-secondary" onClick={decrementQuantity}>-</Button>
                                <span>{quantity}</span>
                                <Button variant="outline-secondary" onClick={incrementQuantity}>+</Button>
                            </div>
                            {isInCart && (
                                <Button variant="" className="remove-btn" onClick={handleRemoveFromCart}><FontAwesomeIcon icon={faTrashCan} /> Remove from cart</Button>
                            )}
                        </div>
                        <div className="btn-wrap">
                            {isInCart ? (
                                <Button variant="success" onClick={goToCart}>Go to Cart <FontAwesomeIcon icon={faArrowRight} /></Button>
                            ) : (
                                <Button variant="primary" onClick={handleAddToCart}><FontAwesomeIcon icon={faBagShopping} /> Add to Cart</Button>
                            )}
                        </div>
                        <div className="product-description">
                            {product.customFields.map(field => (
                                <React.Fragment key={field.custom_field_id}>
                                    <h6>{field.field_name}</h6>
                                    {field.field_type === 'choice' ? (
                                        <ul>
                                            {field.values.map((value, index) => (
                                                <li key={index}>{value}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>{field.values.join(', ')}</p> // Assuming text fields can have multiple values, join them. Adjust as necessary.
                                    )}
                                </React.Fragment>
                            ))}
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ProductDetail;
