import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const styleInputTypes = {
    color: 'color',
    fontFamily: 'dropdown',
    fontWeight: 'dropdown',
    fontStyle: 'dropdown',
    lineHeight: 'dropdown',
    letterSpacing: 'dropdown',
    headingLevel: 'dropdown',
    fontSize: 'dropdown',
    backgroundColor: 'color',
    textAlign: 'select',
    display: 'dropdown',
    justifyContent: 'dropdown',
    alignItems: 'dropdown',
    gap: 'dropdown',
    border: 'text',
    borderRadius: 'dropdown',
    width: 'dropdown',
    height: 'dropdown',
    opacity: 'dropdown',
    boxShadow: 'text',
    textDecoration: 'dropdown',
    borderColor: 'color',         // New addition
    borderWidth: 'dropdown',      // New addition
    alignment: 'dropdown',        // New addition
    link: 'text'                  // New addition for URL input
};

// Options for each style field, including common spacing options for margin and padding sides
export const options = {
    headingLevel: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    fontFamily: [
        { label: 'Arial', value: 'Arial, sans-serif' },
        { label: 'Georgia', value: 'Georgia, serif' },
        { label: 'Times New Roman', value: '"Times New Roman", Times, serif' },
        { label: 'Courier New', value: '"Courier New", Courier, monospace' },
        { label: 'Verdana', value: 'Verdana, sans-serif' }
    ],
    fontWeight: ['normal', 'bold', 'bolder', 'lighter', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
    fontStyle: ['normal', 'italic', 'oblique'],
    lineHeight: ['1', '1.2', '1.5', '1.6', '1.8', '2'],
    letterSpacing: ['0px', '1px', '2px', '3px', '4px'],
    textAlign: ['left', 'center', 'right'],
    fontSize: ['8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px'],
    borderRadius: ['0px', '5px', '10px', '15px', '20px', '25px', '50%'],
    width: ['auto', '50px', '100px', '150px', '200px', '50%', '100%'],
    height: ['auto', '50px', '100px', '150px', '200px', '50%', '100%'],
    opacity: ['1', '0.9', '0.8', '0.7', '0.6', '0.5', '0.4', '0.3', '0.2', '0.1', '0'],
    textDecoration: ['none', 'underline', 'line-through', 'overline'],
    spacing: ['0px', '5px', '10px', '15px', '20px', '25px', '30px'],
    borderWidth: ['0px', '1px', '2px', '3px', '5px', '10px'], // Added options for border width
    alignment: ['left', 'center', 'right'],                   // Added options for alignment
};


const formatLabel = (field) => {
    // Insert space before each uppercase letter and capitalize the first letter
    return field
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
};


const StyleEditor = ({ styles = {}, onChange, editableFields = [] }) => {
    const handleStyleChange = (field, value) => {
        onChange({ ...styles, [field]: value });
    };

    const renderSideInputs = (field) => (
        <Row>
            <Form.Label className="mb-1">{field.charAt(0).toUpperCase() + field.slice(1)}</Form.Label>
            {['Top', 'Right', 'Bottom', 'Left'].map((side) => (
                <Col key={side}>
                    <Form.Control
                        as="select"
                        value={styles[`${field}${side}`] || ''}
                        onChange={(e) => handleStyleChange(`${field}${side}`, e.target.value)}
                    >
                        <option value="">Select {side}</option>
                        {options.spacing.map((spacing) => (
                            <option key={spacing} value={spacing}>
                                {spacing}
                            </option>
                        ))}
                    </Form.Control>
                </Col>
            ))}
        </Row>
    );

    return (
        <Form>
            {editableFields.map((field) => (
                <Form.Group key={field} className="mb-3">
                    {field === 'margin' || field === 'padding' ? (
                        renderSideInputs(field)
                    ) : (
                        <>
                            <Form.Label>{formatLabel(field)}</Form.Label>
                            {styleInputTypes[field] === 'select' ? (
                                <Form.Control
                                    as="select"
                                    name={field}
                                    value={styles[field] || ''}
                                    onChange={(e) => handleStyleChange(field, e.target.value)}
                                >
                                    {options[field].map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Control>
                            ) : styleInputTypes[field] === 'dropdown' ? (
                                <Form.Control
                                    as="select"
                                    name={field}
                                    value={styles[field] || ''}
                                    onChange={(e) => handleStyleChange(field, e.target.value)}
                                >
                                    {options[field].map((option) => (
                                        <option key={option.value || option} value={option.value || option}>
                                            {option.label || option}
                                        </option>
                                    ))}
                                </Form.Control>
                            ) : (
                                <Form.Control
                                    type={styleInputTypes[field] || 'text'}
                                    name={field}
                                    value={styles[field] || ''}
                                    onChange={(e) => handleStyleChange(field, e.target.value)}
                                    placeholder={field === 'boxShadow' ? 'e.g., 0px 4px 10px rgba(0,0,0,0.25)' : ''}
                                />
                            )}
                        </>
                    )}
                </Form.Group>
            ))}
        </Form>
    );
};

export default StyleEditor;
