import React from 'react';
import { Form } from 'react-bootstrap';
import RichTextEditor from '../pagebuilder/RichTextEditor';

const RichTextForm = ({ formValues, setFormValues }) => (
    <Form.Group controlId="formRichTextContent">
        <Form.Label>Rich Text Content</Form.Label>
        <RichTextEditor
            value={formValues.content || ''}
            onChange={(content) => setFormValues({ ...formValues, content })}
        />
    </Form.Group>
);

export default RichTextForm;
