import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';

const ListGroupCard = ({ title, items }) => {
    return (
        <div className="card list-g-wrap">
            <div className="card-header">{title}</div>
            <ul className="list-group list-group-flush">
                {items.map((item, index) => (
                    <li key={index} className="list-group-item"><FontAwesomeIcon icon={faAngleRight} /> {item}</li>
                ))}
            </ul>
        </div>
    );
};

export default ListGroupCard;
