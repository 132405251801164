import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import StoreIcon from '@mui/icons-material/Store'; // Active icon for stores
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'; // Outline icon for stores

import Person2Icon from '@mui/icons-material/Person2';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

function BottomNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  // Define icons for each tab with both active and inactive versions
  const iconMap = {
    Home: {
      active: <HomeIcon />,
      default: <HomeOutlinedIcon />
    },
    Stores: {
      active: <StoreIcon />,
      default: <StoreOutlinedIcon />
    },
    Profile: {
      active: <Person2Icon />,
      default: <Person2OutlinedIcon />
    },
    Cart: {
      active: <ShoppingCartIcon />,
      default: <ShoppingCartOutlinedIcon />
    }
  };

  const navItems = [
    { label: 'Home', value: '/' },
    { label: 'Stores', value: '/products' },
    { label: 'Cart', value: '/cart' },  // Updated label and path for Cart
    { label: 'Profile', value: '/profile' }
  ];

  const handleChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <div className="mobile-view">
    <BottomNavigation value={currentPath} onChange={handleChange} showLabels>
      {navItems.map((item) => (
        <BottomNavigationAction
          key={item.label}
          label={item.label}
          value={item.value}
          icon={currentPath === item.value ? iconMap[item.label].active : iconMap[item.label].default}
          className={currentPath === item.value ? 'active-tab' : ''}
        />
      ))}
    </BottomNavigation>
    </div>
  );
}

export default BottomNav;
