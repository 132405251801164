import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const HeaderFooterCard = ({ header, footer, title, bodyText, styles = {} }) => {
    const headerStyles = styles.header || {};
    const footerStyles = styles.footer || {};
    const titleStyles = styles.title || {};
    const bodyTextStyles = styles.bodyText || {};
    const buttonStyles = styles.button || {};

    return (
        <div className="card hf-card-wrap">
            <div className="card-header" style={headerStyles}>{header}</div>
            <div className="card-body">
                <h5 className="card-title" style={titleStyles}>{title}</h5>
                <p className="card-text" style={bodyTextStyles}>{bodyText}</p>
                <a 
                    href={buttonStyles.link || "#"} 
                    className="btn" 
                    style={{
                        backgroundColor: buttonStyles.backgroundColor,
                        color: buttonStyles.color,
                        fontFamily: buttonStyles.fontFamily,
                        fontSize: buttonStyles.fontSize,
                        fontWeight: buttonStyles.fontWeight,
                        borderRadius: buttonStyles.borderRadius,
                        padding: buttonStyles.padding,
                        margin: buttonStyles.margin,
                        border: buttonStyles.border,
                        letterSpacing: buttonStyles.letterSpacing,
                        lineHeight: buttonStyles.lineHeight
                    }}
                >
                    {buttonStyles.text || "Go somewhere"}
                </a>
            </div>
            <div className="card-footer" style={footerStyles}>{footer}</div>
        </div>
    );
};

export default HeaderFooterCard;
