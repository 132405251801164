export const styleConfig = {
    featureCards: {
        title: ['fontFamily', 'fontSize', 'color', 'fontWeight'],
        description: ['fontFamily', 'fontSize', 'color', 'lineHeight'],
        button: ['fontSize', 'color', 'backgroundColor', 'borderRadius']
    },
    iconCard: {
        icon: ['color', 'fontSize'],
        title: ['fontFamily', 'fontSize', 'color', 'fontWeight'],
        description: ['fontFamily', 'fontSize', 'color', 'lineHeight']
    },
    heading: {
        text: [
            'fontFamily', 'fontSize', 'color', 'textAlign', 'margin', 'padding', 
            'fontWeight', 'fontStyle', 'lineHeight', 'letterSpacing'
        ]
    },
    image: {
        imageStyles: [
            'width', 'height', 'borderRadius', 'boxShadow', 'borderColor', 'borderWidth',
            'margin', 'padding', 'alignment', 'sizing', 'link'
        ]
    },
    faq: {
        title: ['fontFamily', 'fontSize', 'color', 'textAlign', 'margin'],
        question: ['fontFamily', 'fontSize', 'color', 'fontWeight'],
        answer: ['fontFamily', 'fontSize', 'color', 'lineHeight', 'backgroundColor', 'padding']
    },
    socialMediaIcons: {
        container: ['display', 'justifyContent', 'alignItems', 'gap'],
        icon: ['color', 'fontSize', 'margin']
    },
    text: {  // Configuration for TextComponent
        content: [
            'fontFamily', 'fontSize', 'color', 'textAlign', 'margin', 'padding', 
            'fontWeight', 'fontStyle', 'lineHeight', 'letterSpacing', 'textDecoration', 'opacity'
        ]
    },    
    button: {
        buttonStyles: [
            'backgroundColor', 'link', 'border', 'borderRadius',
            'fontFamily', 'fontSize', 'color', 'letterSpacing', 'lineHeight',
            'margin', 'padding'
        ]
    },
    cardComponent: {
        title: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'margin', 'padding', 'letterSpacing', 'lineHeight'
        ],
        image: [
            'width', 'height', 'borderRadius', 'boxShadow', 'borderColor', 'borderWidth',
            'margin', 'padding', 'alignment', 'sizing'
        ],
        bodyText: [
            'fontFamily', 'fontSize', 'color', 'lineHeight', 'textAlign', 
            'margin', 'padding', 'letterSpacing', 'fontWeight'
        ]
    },
    imageOverlayCard: {
        title: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'margin', 
            'letterSpacing', 'lineHeight'
        ],
        bodyText: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'lineHeight', 'letterSpacing', 'margin'
        ],
        image: [
            'height', 'width', 'opacity', 'filter' // Include filter for effects like blur
        ],
        button: [
            'backgroundColor', 'color', 'fontFamily', 'fontSize', 'fontWeight', 
            'padding', 
            'borderRadius', 'letterSpacing', 'lineHeight'
        ]
    },
    headerFooterCard: {
        header: ['fontFamily', 'fontSize', 'color',  'backgroundColor', 'fontWeight', 'textAlign', 'margin', 'padding'],
        footer: ['fontFamily',  'fontSize', 'color', 'backgroundColor', 'fontWeight', 'textAlign', 'margin', 'padding'],
        title: ['fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 'margin', 'padding'],
        bodyText: ['fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 'lineHeight', 'letterSpacing', 'margin', 'padding'],
        button: [
            'backgroundColor', 'color', 'fontFamily', 'fontSize', 'fontWeight', 
            'borderRadius', 'padding', 'margin', 'border', 'letterSpacing', 
            'lineHeight', 'link', 'text'
        ] 
    },
    horizontalCard: {
        title: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'margin', 'padding', 'letterSpacing', 'lineHeight'
        ],
        bodyText: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'lineHeight', 'letterSpacing', 'margin', 'padding'
        ],
        image: [
            'width', 'height', 'borderRadius', 'boxShadow', 'borderColor', 'borderWidth', 
            'margin', 'padding', 'alignment', 'sizing'
        ],
        button: [
            'backgroundColor', 'color', 'fontFamily', 'fontSize', 'fontWeight', 
            'padding', 'borderRadius', 'letterSpacing', 'lineHeight', 'border', 'link'
        ]
    },
    pricingTable: {
        planName: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'margin', 'padding', 'letterSpacing', 'lineHeight'
        ],
        price: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'letterSpacing', 'lineHeight', 'margin', 'padding'
        ],
        features: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'lineHeight', 
            'letterSpacing', 'textAlign', 'margin', 'padding'
        ],
        button: [
            'backgroundColor', 'color', 'fontFamily', 'fontSize', 'fontWeight', 
            'padding', 'borderRadius', 'letterSpacing', 'lineHeight', 'border', 'link'
        ]
    }
};
