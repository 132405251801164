import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconCard = ({ icon, title, description, styles = {} }) => {
    return (
        <div className="card icon-card">
            <div className="card-body text-center">
                <FontAwesomeIcon
                    icon={icon}
                    size="2x"
                    className="mb-3"
                    style={styles.icon || {}}
                />
                <h5 className="card-title" style={styles.title || {}}>
                    {title}
                </h5>
                <p className="card-text" style={styles.description || {}}>
                    {description}
                </p>
            </div>
        </div>
    );
};

export default IconCard;
