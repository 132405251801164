import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { API_URL } from '../config';
import Loading from './component/Loading';
import { useAppData } from './component/AppDataContext';

import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';


const ProductPage = () => {
    const navigate = useNavigate();
    const [sortCriteria, setSortCriteria] = useState('');
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState({ categories: [], attributes: [], priceRange: [], discountRange: [] });
    const [selectedFilters, setSelectedFilters] = useState({
        categories: [],
        attributes: [],
        priceRange: [],
        discountRange: []
    });

    const [loading, setLoading] = useState(false);

    const { subdomain } = useAppData();

    const fetchProducts = useCallback(async () => {
        setLoading(true);
        const queryParams = new URLSearchParams({
            ...selectedFilters.categories.length > 0 && { categories: selectedFilters.categories.join(',') },
            ...selectedFilters.attributes.length > 0 && { attributes: selectedFilters.attributes.join(',') },
            ...selectedFilters.priceRange.length > 0 && { priceRange: selectedFilters.priceRange.join(',') },
            ...selectedFilters.discountRange.length > 0 && { discountRange: selectedFilters.discountRange.join(',') },
        }).toString();

        try {
            const response = await fetch(`${API_URL}/products?${queryParams}&account_key=${subdomain}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            if (data.success && data.products) {
                setProducts(data.products);
                setLoading(false);
            }
        } catch (error) {
            console.error("Failed to fetch products:", error);
        }
    }, [selectedFilters, subdomain]);  // Add all external values the function depends on

    const fetchFilters = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/filters?account_key=${subdomain}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            setFilters(data);
        } catch (error) {
            console.error("Failed to fetch filters:", error);
        }
    }, [subdomain]); // Add all external values the function depends on

    useEffect(() => {
        fetchProducts();
        fetchFilters();
    }, [fetchProducts, fetchFilters]); // Include them in the dependency array

    const handleFilterChange = (filterType, value, isChecked) => {
        setSelectedFilters(prevFilters => {
            const updatedFilters = {...prevFilters};
            if (isChecked) {
                updatedFilters[filterType] = [...updatedFilters[filterType], value];
            } else {
                updatedFilters[filterType] = updatedFilters[filterType].filter(item => item !== value);
            }
            return updatedFilters;
        });
    };

    const handleCardClick = (productId) => {
        navigate(`/products/${productId}`);
    };

    return (
        <div className="margin-top">
            <div className="mobile-view product-filter">
                <ul>
                    <li className="active"><TuneOutlinedIcon /> Filter</li>
                    <li><SwapVertOutlinedIcon /> Sort</li>
                </ul>
            </div>
            <Container fluid className="mobile-product-list">
                <div className="breadcrumbs desktop-view"><div className="container-fluid"><ul><li><Link to="/">Home</Link></li><li>Products</li></ul></div></div>
                <Row>
                    <Col md={2} className="filter-section mt-2 desktop-view">
                        <h5>Filters</h5>
                        <div className="filter-inner">
                            <Form>
                                {/* Render Categories */}
                                <Form.Group className="mb-3 filter-box">
                                    <Form.Label>Categories</Form.Label>
                                    {filters.categories.map((category) => (
                                        <Form.Check key={category.id} type="checkbox" label={category.name} onChange={e => handleFilterChange('categories', category.id, e.target.checked)} />
                                    ))}
                                </Form.Group>
                                
                                {/* Render Attributes */}
                                {filters.attributes.map((attribute) => (
                                    <Form.Group key={attribute.id} className="mb-3 filter-box">
                                        <Form.Label>{attribute.attribute_name}</Form.Label>
                                        {attribute.options.map((option) => (
                                            <Form.Check key={option.id} type="checkbox" label={option.attribute_data} onChange={e => handleFilterChange('attributes', option.id, e.target.checked)} />
                                        ))}
                                    </Form.Group>
                                ))}
                                
                                {/* Render Price Range */}
                                <Form.Group className="mb-3 filter-box">
                                    <Form.Label>Price</Form.Label>
                                    {filters.priceRange.map((range, index) => (
                                        <Form.Check key={index} type="checkbox" label={`$${range.min} - $${range.max}`} onChange={e => handleFilterChange('priceRange', range, e.target.checked)} />
                                    ))}
                                </Form.Group>
                                
                                {/* Render Discount Range */}
                                <Form.Group className="mb-3 filter-box">
                                    <Form.Label>Discount Range</Form.Label>
                                    {filters.discountRange.map((range, index) => (
                                        <Form.Check key={index} type="checkbox" label={`${range.min}% - ${range.max}%`} onChange={e => handleFilterChange('discountRange', range, e.target.checked)} />
                                    ))}
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col md={10} className="product-list">
                        <div className="d-flex justify-content-end mb-3 desktop-view">
                            <Form.Group className="sort-wrap">
                                <Form.Label className="me-2">Sort by</Form.Label>
                                <Form.Select value={sortCriteria} onChange={(e) => setSortCriteria(e.target.value)} style={{ width: 'auto' }}>
                                    <option value="">Select</option>
                                    <option value="name">Name</option>
                                    <option value="price">Price</option>
                                    {/* Add more sort criteria as needed */}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="product-inner-wrap" >
                            {loading ? <Loading text="" /> : ''}

                            <Row xs={2} md={3} lg={5} className="g-4">
                                {products.map((product, index) => (
                                    <Col key={index}>
                                        <Card className="h-100" onClick={() => handleCardClick(product.product_id)} style={{ cursor: 'pointer' }}>
                                            <div className="image-wrap">
                                                <Card.Text className="rating">4.5 <FontAwesomeIcon icon={faStar} /></Card.Text>
                                                <Card.Img variant="top" src={`${product.media_urls}`} alt="Product Image" />
                                            </div>
                                            <Card.Body>
                                                <Card.Title>{product.product_name}</Card.Title>
                                                <Card.Text className="product-description">{product.description}</Card.Text>

                                                <Card.Text className="price">
                                                    {product.discounted_price && parseFloat(product.discounted_price) < parseFloat(product.price) ? (
                                                        <>
                                                            <span className="product-discountedPrice">${parseFloat(product.discounted_price).toFixed(2)}</span>
                                                            <span className="product-strike" style={{ textDecoration: 'line-through' }}>
                                                                ${parseFloat(product.price).toFixed(2)}
                                                            </span>
                                                            <span className="product-discountPercentage">
                                                                (Save {((1 - parseFloat(product.discounted_price) / parseFloat(product.price)) * 100).toFixed(0)}%)
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <span className="product-discountedPrice">${parseFloat(product.price).toFixed(2)}</span>
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ProductPage;
