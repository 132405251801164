import React, { useEffect, useState } from 'react';

const CountdownTimerComponent = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className="countDown-wrap">
            <ul>
                <li>{timeLeft.days || '0'} <span>Days</span></li>
                <li>{timeLeft.hours || '0'} <span>Hours</span></li>
                <li>{timeLeft.minutes || '0'} <span>Minutes</span></li>
                <li>{timeLeft.seconds || '0'} <span>Seconds</span></li>
            </ul>
        </div>
    );
};

export default CountdownTimerComponent;
